import { Controller } from "@hotwired/stimulus";
import confetti from "canvas-confetti";
import cookie from "cookiejs";

export default class extends Controller {
  static targets = ["overlay", "closeBtn", "timerBar"];
  static values = { name: String, cookie: String, closable: Boolean, confetti: Boolean, timer: Number };

  modal_panel = this.element.querySelector(".modal__panel");

  initialize() {
    this.element[this.identifier] = this;
    this.fillTimer = this.fillTimer.bind(this);
    this.close = this.close.bind(this);
  }

  connect() {
    if (this.closableValue) this.overlayTarget.addEventListener("click", this.close);
    if (this.element.classList.contains("open") && this.confettiValue) confetti();
    this.closeBtnTargets.forEach(
      ((btn) => {
        btn.addEventListener("click", this.close);
      }).bind(this)
    );

    this.bindTogglers();

    if (this.cookieValue && this.element.classList.contains("open")) {
      if (cookie.get(this.cookieValue)) {
        this.element.classList.remove("open");
        // For sliding panel on mobile
        this.modal_panel.classList.remove("open");
      }
    }

    if (this.element.classList.contains("open")) {
      this.resetTimer();
    }
  }

  disconnect() {
    if (this.closableValue) this.overlayTarget.removeEventListener("click", this.close);
    this.closeBtnTargets.forEach(
      ((btn) => {
        btn.removeEventListener("click", this.close);
      }).bind(this)
    );
  }

  fillTimer() {
    this.timer += 10;
    this.timerBarTarget.style.width = `${(this.timer / this.timerValue) * 100}%`;
    if (this.timer >= this.timerValue) {
      clearInterval(this.timerInterval);
      this.close();
    }
  }

  bindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-modal="${this.nameValue}"]`).forEach(
      ((elem) => {
        const scrollTo = elem.dataset.modalScrollToId;
        elem.addEventListener(
          "click",
          ((e) => {
            this.open(e, scrollTo);
          }).bind(this)
        );
      }).bind(this)
    );
  }

  open(e, scrollToId = null) {
    if (e) e.preventDefault();
    this.element.classList.add("open");
    // For sliding panel on mobile
    this.modal_panel.classList.add("open");

    var viewportOffset = this.overlayTarget.getBoundingClientRect();
    // these are relative to the viewport
    var top = viewportOffset.top;
    var left = viewportOffset.left;
    this.overlayTarget.style.top = `-${top}px`;
    this.overlayTarget.style.left = `-${left}px`;
    this.overlayTarget.style.width = `100vw`;
    this.overlayTarget.style.height = `100vh`;

    // Prevent body scroll
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    if (scrollToId) {
      const scrollElem = this.element.querySelector(`[id="${scrollToId}"]`);
      setTimeout(() => {
        scrollElem.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 400);
    }

    this.resetTimer();
  }

  close() {
    this.element.classList.remove("open");
    // For sliding panel on mobile
    this.modal_panel.classList.remove("open");

    if (this.cookieValue) cookie.set(this.cookieValue, true);

    // Check if all modals are closed to re-enable body scroll
    const hasModalsOpen = document.querySelectorAll(".mba-modal.open").length > 0;
    if (!hasModalsOpen) {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
    }

    if (this.element.classList.contains("modal--fullscreen")) {
      this.element.classList.add("modal--fullscreen-close");
      setTimeout(() => {
        this.element.classList.remove("modal--fullscreen-close");
      }, 500);
    }
  }

  resetTimer() {
    if (this.timerValue) {
      this.timer = 0;
      this.timerInterval = setInterval(this.fillTimer, 10);
    }
  }
}
