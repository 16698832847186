import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "toggleMenuBtn", "appBody"];
  static values = { name: String, open: Boolean };
  isBurgerMenuOpen = false;

  initialize() {
    this.preventScroll = this.preventScroll.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  preventScroll = (e) => {
    e.preventDefault();
  };

  connect() {
    this.updateBurgerMenu();
    window.addEventListener("resize", (window.onWindowResize = this.updateBurgerMenu.bind(this)));

    this.menuTarget.addEventListener("touchmove", this.preventScroll);
    this.toggleMenuBtnTarget.addEventListener("click", this.toggleMenu);
    this.bindTogglers();
    if (this.hasOpenValue && this.openValue) {
      this.toggleMenu();
    }
  }

  disconnect() {
    window.removeEventListener("resize", window.onWindowResize);
    this.menuTarget.removeEventListener("touchmove", this.preventScroll);
    this.unbindTogglers();
  }

  bindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-burger=${this.nameValue}]`).forEach((elem) => {
      elem.addEventListener("click", this.toggleMenu);
    });
  }

  unbindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-burger=${this.nameValue}]`).forEach((elem) => {
      elem.removeEventListener("click", this.toggleMenu);
    });
  }

  /* Burger Menu */
  toggleMenu(event) {
    if (event) event.preventDefault();
    this.menuTarget.classList.toggle("open");
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;

    this.updateBurgerMenu();
  }

  close() {
    this.menuTarget.classList.remove("open");
    this.isBurgerMenuOpen = false;
    window.scrollTo(0, 0);

    // Check if all modals are closed to re-enable body scroll
    const hasBurgersOpen = document.querySelectorAll(".burger-menu.open").length > 0;
    if (!hasBurgersOpen) {
      document.querySelector("body").classList.remove("overflow-hidden");
      document.querySelector(".app-body").classList.remove("overflow-hidden");
    }
  }

  closeAll() {
    this.isBurgerMenuOpen = false;
    document.querySelectorAll(".burger-menu.open").forEach((e) => e.classList.remove("open"));
    document.querySelector("body").classList.remove("overflow-hidden");
    document.querySelector(".app-body").classList.remove("overflow-hidden");
  }
  updateBurgerMenu() {
    if (this.isBurgerMenuOpen) {
      document.querySelector("body").classList.add("overflow-hidden");
      document.querySelector(".app-body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
      document.querySelector(".app-body").classList.remove("overflow-hidden");
    }
  }
}
