import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["filterForm", "orderInput", "descendingInput"]
  static values = {order: String, descending: Boolean}

  #order = "";
  #descending = null;
  #filterForm = null
  #table;

  connect() {
    this.#table = this.element.querySelector(".data__table");
    this.#order = this.orderValue;
    this.#descending = this.descendingValue;
    this.#filterForm = this.element.closest("form")
    this.#updateOrderInputs();
    this.setColumnsWidth();
  }

  setColumnsWidth() {
    let colWidth = []
    this.#table.querySelectorAll('.datatable__header, .data__row').forEach((tr) => {
      tr.querySelectorAll('.header__column, .data__cell').forEach((td, i) => {
        if (!colWidth[i] || colWidth[i] < td.offsetWidth)
          colWidth[i] = td.offsetWidth
      });
    });
    this.#table.querySelectorAll('.datatable__header, .data__row').forEach((tr) => {
      tr.querySelectorAll('.header__column, .data__cell').forEach((td, i) => {
        td.style.width = `${colWidth[i]}px`;
      });
    });
  }

  sortColumn(e) {
    this.setOrder(e.params.sortColumn)
  }

  setOrder(order) {
    if (this.#order != order) {
      this.#order = order;
      this.#descending = false;
    } else {
      switch (this.#descending) {
        case null:
          this.#descending = false;
          break;
        case true:
          this.#descending = null;
          break;
        case false:
          this.#descending = true;
          break;
      }
      if (this.#descending === null)
        this.#order = null;
    }
    this.#updateOrderInputs();
    this.#submitFilterForm();
  }

  #updateOrderInputs() {
    this.orderInputTarget.value = this.#order;
    this.descendingInputTarget.value = this.#descending;
  }

  #submitFilterForm() {
    this.#filterForm.requestSubmit();
  }
}
