import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["inputMin", "inputMax"]
    static values = {min: Number, max: Number}

    connect() {
        this.inputMinTarget.addEventListener("input", ((e) => {
            this.onInput(e)
            this.onInputMin()
        }).bind(this))
        this.inputMinTarget.addEventListener("change", (() => {
            this.onChangeMin()
        }).bind(this))
        this.inputMaxTarget.addEventListener("input", ((e) => {
            this.onInput(e)
        }).bind(this))
        this.inputMaxTarget.addEventListener("change", (() => {
            this.onChangeMax()
        }).bind(this))
    }

    onInput(e) {
        const val = e.currentTarget.value
        e.currentTarget.value = val.replace(/[^0-9]/g, '')
    }

    onInputMin() {
        const valMin = parseInt(this.inputMinTarget.value)
        const valMax = parseInt(this.inputMaxTarget.value)
        if (valMin > valMax) {
            this.inputMaxTarget.value = valMin
            this.onChangeMax()
        }
    }

    onInputMax() {
        const valMin = parseInt(this.inputMinTarget.value)
        const valMax = parseInt(this.inputMaxTarget.value)
        if (valMin > valMax) {
            this.inputMinTarget.value = valMax
            this.onChangeMin()
        }
    }

    onChangeMin() {
        const valMin = parseInt(this.inputMinTarget.value)
        const valMax = parseInt(this.inputMaxTarget.value)
        if (valMin > this.maxValue) {
            this.inputMinTarget.value = this.maxValue
            this.onInputMin()
        } else if (valMin < this.minValue) {
            this.inputMinTarget.value = this.minValue
            this.onInputMin()
        }
    }

    onChangeMax() {
        const valMin = parseInt(this.inputMinTarget.value)
        const valMax = parseInt(this.inputMaxTarget.value)
        if (valMin > valMax) {
            this.inputMinTarget.value = valMax
            this.onChangeMin()
        }
        if (valMax > this.maxValue) {
            this.inputMaxTarget.value = this.maxValue
            this.onInputMax()
        } else if (valMax < this.minValue) {
            this.inputMaxTarget.value = this.minValue
            this.onInputMax()
        }
    }
}
