import {Controller} from "@hotwired/stimulus";
import Quill from "quill";
import { HtmlToDelta } from 'quill-delta-from-html';

export default class extends Controller {
  static targets = ["editor", "toolbar", "summary", "editBtn", "cancelBtn", "submitBtn"]
  static values = { text: String, readonly: Boolean, editable: Boolean, resizable: Boolean}

  #input = null
  #editor = null
  #textarea = null

  initialize() {
    this.onInput = this.onInput.bind(this);
  }

  connect() {
    this.#textarea = this.element.querySelector("textarea")
    if (this.#textarea)
      this.initTextarea()
    if (this.hasEditorTarget)
      this.initEditor()
  }

  disconnect() {
    this.element.removeEventListener("input", this.onInput);
  }

  initTextarea() {
    this.offset = this.#textarea.offsetHeight - this.#textarea.clientHeight;
    this.#textarea.addEventListener("input", this.onInput);
    this.resize();
  }

  initEditor() {
    this.#input = this.element.querySelector('textarea')
    this.#editor = new Quill(this.editorTarget, {
      modules: {
        toolbar: this.toolbarTarget,
      },
      readOnly: this.readonlyValue,
    });
    this.#editor.on('text-change', (delta, oldDelta, source) => {
      this.refreshSummary()
    })
    if (this.hasTextValue) {
      this.#editor.setContents(new HtmlToDelta().convert(this.textValue))
    }
    if (this.readonlyValue == true && this.editableValue == true) {
      this.hideEdit()
    }
  }

  showEdit() {
    this.#editor.enable()
    this.editBtnTarget.classList.add("hidden")
    this.cancelBtnTarget.classList.remove("hidden")
    this.submitBtnTarget.classList.remove("hidden")
    this.element.classList.remove("disabled")
  }

  hideEdit() {
    this.#editor.disable()
    this.editBtnTarget.classList.remove("hidden")
    this.cancelBtnTarget.classList.add("hidden")
    this.submitBtnTarget.classList.add("hidden")
    this.#editor.setText("")
    this.#editor.clipboard.dangerouslyPasteHTML(this.textValue)
    this.element.classList.add("disabled")
  }

  refreshSummary() {
    const text = this.#editor.root.innerHTML;
    if (!this.hasSummaryTarget) {
      this.#input.value = text;
      return false
    }
    const re = /<h1[^>]*>((?!<\/h1>).)*<\/h1>/gmi
    const res = [...text.matchAll(re)];
    this.summaryTarget.innerHTML = ""
    res.forEach(r => {
      let li = document.createElement("li");
      li.innerHTML = r[0];
      li.innerHTML = (li.textContent || li.innerText).trim()
      if(li.innerHTML) {
        li.dataset.action = "click->form--text-area--component#goToTitle"
        this.summaryTarget.appendChild(li)
      }
    })
    this.#input.value = text
  }

  goToTitle(e) {
    const li = e.currentTarget
    var headings = document.evaluate(`//h1[contains(., "${li.innerText}")]`, this.editorTarget, null, XPathResult.ANY_TYPE, null );
    var heading = headings.iterateNext();
    if (heading)
      heading.scrollIntoView()
  }

  onInput() {
    this.resize();
  }

  resize() {
    if (this.resizableValue == false)
      return;
    this.#textarea.style.height = 'auto';
    this.#textarea.style.height = this.#textarea.scrollHeight + this.offset + 'px';
  }
}
