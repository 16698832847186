import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

  static targets = ["addressInput"]

  initialize() {
    this.onInputChanged = this.onInputChanged.bind(this)
  }

  connect() {
    this.element[this.identifier] = this;
    if (this.hasAddressInputTarget) {
      this.addressInputTarget.addEventListener("change", this.onInputChanged)
    }
  }

  onInputChanged(e) {
    const formGroup = e.currentTarget.closest(".oq-form-group")
    const fieldError = formGroup.querySelector(".field-error")
    if (!this.addressInputTarget.value) {
      formGroup.classList.add("is-invalid")
      fieldError.innerText = "Veuillez sélectionner une adresse parmi la liste."
    } else {
      formGroup.classList.remove("is-invalid")
      fieldError.innerText = ""
    }
  }
}
