import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['button']

    connect() {
        this.buttonTargets.forEach((button => {
            button.addEventListener('click', this.select.bind(this))
        }).bind(this))
    }

    select(e) {
        this.buttonTargets.forEach((button => {
            button.classList.remove('active')
        }))
        const btn = e.currentTarget
        btn.classList.add('active')
    }
}
