import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["businessPartnerSelectInput", "landlordsSelectInput", "moralLandlordsSelectInput", "encadrementUrlInput", "constructionPeriodInput", "propertyTypeIdInput", "constructionDateInput", "referenceRentInput"];

  // MAJ de l'encadrement du loyer
  propertyGetAndSetEncadrementValue() {
    if (!this.hasEncadrementUrlInputTarget) {
      return false;
    }

    const constructionDate = this.constructionDateInputTarget.value;
    const constructionPeriod = this.constructionPeriodInputTarget.value && this.constructionPeriodInputTarget.value[0].value;
    const propertyTypeId = this.propertyTypeIdInputTarget.value && this.propertyTypeIdInputTarget.value[0].value;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch(this.encadrementUrlInputTarget.value, {
      method: "POST",
      body: JSON.stringify({
        construction_date: constructionDate,
        construction_period: constructionPeriod,
        property_type_id: propertyTypeId,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const encadrementRef = this.referenceRentInputTarget;
        const oldValue = encadrementRef.value;

        if (response.reference_value && oldValue !== response.reference_value) {
          encadrementRef.value = response.reference_value;
          encadrementRef.dispatchEvent(new Event("change"));
          alert("Loyer de référence par m2:\nNouvelle valeur: " + response.reference_value + "\nAncienne valeur: " + oldValue);
        }
      });
  }


  // EN STANDBY, NE FONCTIONNE PAS, IL FAUT FAIRE UNE MECANIQUE GENERIQUE DE DEPENDANCE DES INPUTS
  updateLandlordsFromBusinessPartner(e) {
    // const businessPartnerId = this.businessPartnerSelectInputTarget.value[0].value;
    // this.landlordsSelectInputTarget.dataset.ajaxUrl = `/admin/landlords/landlords_by_business_partner_options?business_partner_id=${businessPartnerId}`;
    // this.landlordsSelectInputTarget.setAttribute(
    //   "ajax--url",
    //   `/admin/landlords/landlords_by_business_partner_options?business_partner_id=${businessPartnerId}`
    // );
    // this.moralLandlordsSelectInputTarget.dataset.ajaxUrl = `/admin/moral_landlords/moral_landlords_by_business_partner_options?business_partner_id=${businessPartnerId}`;
    // this.moralLandlordsSelectInputTarget.setAttribute(
    //   "ajax--url",
    //   `/admin/moral_landlords/moral_landlords_by_business_partner_options?business_partner_id=${businessPartnerId}`
    // );
    // this.initSearchableSelects(this.landlordsSelectInputTarget);
    // this.initSearchableSelects(this.moralLandlordsSelectInputTarget);
  }

  // Re-définition de la fonction pour pouvoir re-appeler la route pour le searchable_select
  // initSearchableSelects(inputs, extra) {
  //   inputs.each(function () {
  //     var item = $(this);

  //     // reading from data allows <input data-searchable_select='{"tags": ['some']}'>
  //     // to be passed to select2
  //     var options = $.extend(extra || {}, item.data("searchableSelect"));
  //     var url = item.data("ajaxUrl");

  //     if (url) {
  //       $.extend(options, {
  //         ajax: {
  //           url: url,
  //           dataType: "json",

  //           data: function (params) {
  //             return {
  //               term: params.term,
  //               page: pageParamWithBaseZero(params),
  //             };
  //           },
  //         },
  //       });
  //     }

  //     item.select2(options);
  //   });
  // }

  // pageParamWithBaseZero(params) {
  //   return params.page ? params.page - 1 : undefined;
  // }
}
