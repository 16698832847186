import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["slide", "tab"]

    connect() {
        this.tabTargets.forEach(tab => {
            tab.addEventListener('click', this.select.bind(this))
        })
        // Init first slide
        this.tabTargets[0].classList.add('active')
        this.slideTargets[0].classList.add('active')
    }

    select(e) {
        this.tabTargets.forEach(tab => tab.classList.remove('active'))
        this.slideTargets.forEach(slide => slide.classList.remove('active'))

        const tab = e.currentTarget
        const slide_id = tab.dataset.slideId
        const slide = this.slideTargets.find((s) => s.dataset.slideId == slide_id)
        slide.classList.add('active')
        tab.classList.add('active')
    }
}
