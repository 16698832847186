import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "panel"];

  connect() {
    setTimeout(() => {
      this.open()
    }, 50)
  }

  open(e) {
    if (e)
      e.preventDefault()
    this.element.classList.add("open")
    this.element.focus()
  }

  close(e) {
    if (e)
      e.preventDefault()
    this.element.classList.remove("open");
    setTimeout(() => {
      this.element.classList.add('hidden')
    }, 500)
  }

  escKeyPressed(e) {
    if (e.target.tagName.toLowerCase() == "body" || e.target == this.element) {
      this.close(e)
      e.stopPropagation()
    }
  }
}
