import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inventoryTypeInput", "leaseInputGroup", "leaseInput", "propertyInputGroup", "propertyInput", "officerInput", "officerInputGroup", "officerTypeInput"]
  static values = {officerUrls: Object}

  #inventoryTypeController
  #officerTypeController
  #officerController
  #leaseController
  #propertyController
  connect() {
  }
  updateInputs() {
    switch (this.inventoryTypeValue) {
      case "Inventory::Onboarding":
        this.leaseInputGroupTarget.classList.add("hidden")
        this.propertyInputGroupTarget.classList.remove("hidden")
        this.leaseController?.clearValue()
        break
      case "Inventory::Ingoing":
      case "Inventory::Outgoing":
        this.leaseInputGroupTarget.classList.remove("hidden")
        this.propertyInputGroupTarget.classList.add("hidden")
        this.propertyController?.clearValue()
        break
      default:
        this.leaseInputGroupTarget.classList.add("hidden")
        this.propertyInputGroupTarget.classList.add("hidden")
        this.leaseController?.clearValue()
        this.propertyController?.clearValue()
        break
    }

    if (this.officerTypeValue) {
      this.officerInputGroupTarget.classList.remove("hidden")
      this.updateOfficerUrl()
    } else {
      this.officerInputGroupTarget.classList.add("hidden")
      this.officerController?.clearValue()
    }
  }
  updateOfficerUrl() {
    if (!this.officerController)
      return
    // On met a jour l url ajax si changement de type de responsable
    const url = this.officerUrlsValue[this.officerTypeValue]
    if (url != this.officerController.ajaxUrl) {
      this.officerController.clearValue()
      this.officerController.setAjaxUrl(url)
    }
  }
  get inventoryTypeValue() {
    this.#inventoryTypeController ||= this.inventoryTypeInputTarget["form--collection-select--component"]
    return this.#inventoryTypeController?.value
  }
  get officerTypeValue() {
    this.#officerTypeController ||= this.officerTypeInputTarget["form--collection-select--component"]
    return this.#officerTypeController?.value
  }
  get officerController() {
    this.#officerController ||= this.officerInputTarget["form--collection-select--component"]
    return this.#officerController
  }

  get leaseController() {
    this.#leaseController ||= this.leaseInputTarget["form--collection-select--component"]
    return this.#leaseController
  }
  get propertyController() {
    this.#propertyController ||= this.propertyInputTarget["form--collection-select--component"]
    return this.#propertyController
  }
}