import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['alertElem'];
  static values = { autoDismiss: Boolean }

  connect() {
    const elem = this.alertElemTarget;
    if (this.autoDismissValue === true) {
      setTimeout(function(){
        elem.remove();
      }, 2500);
    }
  }
}
