import {Controller} from "@hotwired/stimulus";

const colors = [
  "#111111", "#0084FA", "#F26619", "#7D4FFE",
  "#FE277E", "#679436", "#DE2E4B", "#082BCB",
  "#F3BB00", "#2A9D8F", "#0FAC71", "#2DBDB4",
];

export default class extends Controller {
  static targets = ["colorPicker", "input"];

  _selectedColor = "#FFFFFF";

  initialize() {
  }

  connect() {
    this.switchColor();
  }

  switchColor() {
    let color = this._selectedColor;
    while (color == this._selectedColor)
      color = colors[Math.floor(Math.random() * colors.length)];
    this._selectedColor = color;
    this.colorPickerTarget.style.backgroundColor = this._selectedColor;
    this.inputTarget.value = this._selectedColor;
  }
}
