import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button"];
  static values = { filesList: String };

  filesList = null;
  form = null;

  files = [];

  initialize() {
    this.filesList = document.querySelector(`#${this.filesListValue}`);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFileInputValue = this.clearFileInputValue.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.form = this.element.closest("form");
  }

  connect() {
    this.inputTarget.addEventListener("change", this.onInputChange);
    this.form.addEventListener("reset", this.clearFileInputValue);
    this.buttonTarget.addEventListener("click", this.openDialog);
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.onInputChange);
    this.form.removeEventListener("reset", this.clearFileInputValue);
    this.buttonTarget.removeEventListener("click", this.openDialog);
  }

  openDialog(e) {
    e.preventDefault();
    e.stopPropagation();
    this.inputTarget.click();
  }

  clearFileInputValue() {
    this.inputTarget.value = null;
    this.files = [];
    this.updateFilePreview();
  }

  onInputChange(e) {
    e.stopPropagation();
    e.preventDefault();

    const fileList = this.inputTarget.files;
    this.files = this.files.concat(Array.from(fileList));
    this.updateFileInputValue();
  }

  updateFileInputValue() {
    var b = new DataTransfer();
    for (var i = 0, len = this.files.length; i < len; i++) b.items.add(this.files[i]);
    this.inputTarget.files = b.files;
    const fileList = this.inputTarget.files;
    this.updateFilePreview();
  }

  updateFilePreview() {
    this.filesList.innerHTML = "";

    this.files.forEach((file) => {
      let preview = document.createElement("div");
      preview.classList.add("file-preview");

      let mainContainer = document.createElement("div");
      mainContainer.classList.add("main-container");

      let fileName = document.createElement("span");
      fileName.classList.add("file-name");
      fileName.innerHTML = file.name;
      mainContainer.append(fileName);

      let confirmation = document.createElement("div");
      confirmation.classList.add("confirmation");
      confirmation.innerHTML =
        '<svg class=\'w-6 h-6 check-round\' fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="CurrentColor" stroke-linecap="round" stroke-linejoin="round"/>\n' +
        "</svg>" +
        '<span class="confirmation-span">Pièce jointe téléchargée</span>';
      mainContainer.append(confirmation);

      let docIcon = document.createElement("div");
      docIcon.classList.add("doc-icon");
      docIcon.innerHTML =
        '<svg class=\'w-6 h-6\' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">\n' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />\n' +
        "</svg>";

      let deleteBtn = document.createElement("div");
      deleteBtn.classList.add("delete-btn");
      deleteBtn.innerHTML =
        '<svg class=\'w-4 h-4\' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />\n' +
        "</svg>";
      deleteBtn.addEventListener(
        "click",
        ((e) => {
          this.deleteFile(file);
        }).bind(this)
      );
      preview.append(docIcon);
      preview.append(mainContainer);
      preview.append(deleteBtn);
      this.filesList.append(preview);
    });
  }

  deleteFile(file) {
    let index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
    this.updateFileInputValue();
  }
}
