import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { template } from "lodash";
export default class extends Controller {
  static targets = ["fileInput", "list", "dropzone", "fileTemplate", "fileButton"];
  static values = {
    uploadUrl: String,
    deleteUrl: String,
    files: String,
    allowedTypes: Array,
    maxFileSize: String,
    acceptsMultipleFiles: Boolean,
  };

  files = [];
  uploadedFiles = [];
  _files_loading = 0;
  _form = null;
  _formSubmitButton = null;
  _fileTemplate = null;
  _disable_if_empty = true;

  // Used to know if mouse is still over the dragzone when it enters multiple child components (0 = leave, > 0 = over)
  _dragEnterCounter = 0;

  connect() {
    // This hangs a reference to the Stimulus controller instance off the DOM element that has the same name as the controller itself.
    this.element[this.identifier] = this;
    this._fileTemplate = template(this.fileTemplateTarget.innerHTML);
    this._form = this.element.closest("form");
    this._disable_if_empty = this.fileInputTarget.dataset.disableSubmitIfEmpty;
    if (this._form) {
      if (typeof(this.element.dataset.formsubmitbuttonid) !== 'undefined') {
        this._formSubmitButton = this._form.querySelector(`button#${this.element.dataset.formsubmitbuttonid}`);
      } else {
        this._formSubmitButton = this._form.querySelector("button[type='submit']");
      }
      if (this.files.length === 0 && this._formSubmitButton && this._disable_if_empty === 'true') {
        this._formSubmitButton.disabled = true;
      }
    }

    // this.dropzoneTarget.addEventListener("click", this.openFileBrowser.bind(this))
    this.dropzoneTarget.addEventListener("dragover", this.onDragOver.bind(this));
    this.dropzoneTarget.addEventListener("dragenter", this.onDragEnter.bind(this));
    this.dropzoneTarget.addEventListener("dragleave", this.onDragLeave.bind(this));
    this.dropzoneTarget.addEventListener("drop", this.onDrop.bind(this));

    this.fileInputTarget.addEventListener("change", this.fileSelected.bind(this));
    this.files = JSON.parse(this.filesValue);
    this.listTarget.innerHTML = "";

    this.files.forEach((f) => {
      const item = this.createListItem(f);
      this.listTarget.appendChild(item);
    });

    if (this.files.length >= 1 && this.acceptsMultipleFilesValue === false) {
      this.fileButtonTarget.disabled = true;
    }
  }

  openFileBrowser(e) {
    if (!this.fileInputTarget.value) this.fileInputTarget.click();
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragEnter(e) {
    this._dragEnterCounter++;
    this.dropzoneTarget.classList.add("dragover");
  }

  onDragLeave(e) {
    this._dragEnterCounter--;
    if (this._dragEnterCounter <= 0) {
      this._dragEnterCounter = 0;
      this.dropzoneTarget.classList.remove("dragover");
    }
  }

  onDrop(e) {
    e.preventDefault();
    this.dropzoneTarget.classList.remove("dragover");
    this.fileInputTarget.files = e.dataTransfer.files;
    this.dropzoneTarget.click();
    if ("createEvent" in document) {
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      this.fileInputTarget.dispatchEvent(evt);
    } else {
      this.fileInputTarget.fireEvent("onchange");
    }
  }

  fileSelected(event) {
    const input = event.target;
    if (!input.files.length) return false;
    this.uploadedFiles = [];
    Array.from(input.files).forEach((file) => this.uploadFile(file));
    input.value = null;
  }

  uploadFile(file) {
    const url = this.fileInputTarget.dataset.directUploadUrl;
    let uploader = new Uploader(file, url, this);
  }

  deleteFile(e) {
    const item = e.currentTarget.closest(".list__item");

    const signed_id = item.dataset.signedId;
    const public_uid = item.dataset.publicUid;

    const statusText = item.querySelector(".status-text");
    statusText.innerHTML = "Suppression en cours...";
    item.classList.add("deleting");

    if (this.deleteUrlValue) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content

      fetch(this.deleteUrlValue, {
        method: "delete",
        body: JSON.stringify({ signed_id: signed_id }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        credentials: "same-origin",
      })
        .then((response) => {
          let isTurbo = response.headers.get("Content-Type").includes("turbo-stream");
          if (isTurbo) {
            return response.text().then((d) => ({
              turboStream: true,
              data: d,
            }));
          } else {
            return response.json().then((d) => ({
              turboStream: false,
              data: d,
            }));
          }
        })
        .then((data) => {
          if (data.turboStream) {
            document.body.insertAdjacentHTML("beforeend", data.data);
          }
          statusText.innerHTML = "";
          item.remove();
          this.removeFileForItem(item);
          const input = this._form.querySelector(`input[value="${signed_id}"]`);
          if (input) { input.remove(); }
          if (this.acceptsMultipleFilesValue === false) {
            let uploadedFiles = this._form.querySelectorAll(`input[name="${this.fileInputTarget.name}"][type="hidden"]`);
            if (uploadedFiles.length == 0 || this.files.length == 0) {
              this.fileButtonTarget.disabled = false;
            }
          }
        });
    } else {
      const input = this._form.querySelector(`input[value="${signed_id}"]`);
      input.remove();
      item.remove();
      this.removeFileForItem(item);
      this.removeUploadedFileForItem(item);
      if (this.files.length === 0 && this.uploadedFiles.length === 0 && this._formSubmitButton && this._disable_if_empty === 'true') {
        this._formSubmitButton.disabled = true;
      }
    }
  }

  removeFileForItem(item) {
    const index = this.files.findIndex((f) => f.signed_id === item.signed_id);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  removeUploadedFileForItem(item) {
    let index = -1;
    if (item instanceof Element) {
      index = this.uploadedFiles.findIndex((f) => f.signed_id === item.dataset.signedId);
    } else {
      index = this.uploadedFiles.findIndex((f) => f.signed_id === item.signed_id);
    }
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  createListItem(file) {
    const itemData = {
      signedId: typeof file.signed_id !== "undefined" && file.signed_id ? file.signed_id : "",
      publicUid: typeof file.public_uid !== "undefined" && file.public_uid ? file.public_uid : "",
      filename: file.name,
    };
    const item = this.createElementFromHTML(this._fileTemplate(itemData));
    return item;
  }
}

class Uploader {
  constructor(file, url, controller) {
    this.upload = new DirectUpload(file, url, this);
    this.controller = controller;

    this.item = this.controller.createListItem(file);
    this.controller.listTarget.appendChild(this.item);

    this.controller._files_loading++;
    if (this.controller._formSubmitButton) this.controller._formSubmitButton.disabled = true;
    const statusText = this.item.querySelector(".status-text");
    statusText.innerHTML = "Envoi en cours...";

    // Check mime type
    if (!this.controller.allowedTypesValue.includes(file.type) && !this.controller.allowedTypesValue.includes("*")) {
      statusText.innerHTML = "Format de fichier non accepté";
      this.item.classList.add("error");
      this.controller._files_loading--;
      if (this.controller._formSubmitButton && this.controller._disable_if_empty === 'false') {
        this.controller._formSubmitButton.disabled = false;
      }
      return false;
    }

    // Check file size
    if (this.controller.maxFileSizeValue && file.size > this.controller.maxFileSizeValue) {
      statusText.innerHTML = "Le fichier est trop volumineux";
      this.item.classList.add("error");
      this.controller._files_loading--;
      if (this.controller._formSubmitButton && this.controller._disable_if_empty === 'false') {
        this.controller._formSubmitButton.disabled = false;
      }
      return false;
    }

    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
        this.item.classList.add("error");
        statusText.innerHTML = "Une erreur s'est produite";
        this.item.classList.remove("upload-progress");
        this.item.classList.add("upload-end");
      } else {
        this.item.dataset.signedId = blob.signed_id;
        statusText.innerHTML = "";
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content

        if (controller.uploadUrlValue) {
          // Post the created attachment
          fetch(controller.uploadUrlValue, {
            method: "post",
            body: JSON.stringify({ file: blob }),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            credentials: "same-origin",
          })
            .then((response) => {
              let isTurbo = response.headers.get("Content-Type").includes("turbo-stream");
              if (isTurbo) {
                return response.text().then((d) => ({
                  turboStream: true,
                  data: d,
                }));
              } else {
                return response.json().then((d) => ({
                  turboStream: false,
                  data: d,
                }));
              }
            })
            .then((data) => {
              this.controller._files_loading--;
              if (this.controller._files_loading <= 0 && this.controller._formSubmitButton) {
                this.controller._formSubmitButton.disabled = false;
              }
              if (data.data.error) {
                this.item.classList.add("error");
                statusText.innerHTML = data.data.error;
              } else {
                statusText.innerHTML = "";
                this.item.dataset.publicUid = data.data.public_uid;
                if (data.turboStream) {
                  document.body.insertAdjacentHTML("beforeend", data.data);
                } else {
                  Turbo.visit(window.location.toString(), { action: "replace" });
                }
              }
            })
            .catch((error) => {
              this.item.classList.add("error");
              statusText.innerHTML = "Une erreur s'est produite";
              if (this._disable_if_empty === 'false') {
                this.controller._formSubmitButton.disabled = false;
              }
            })
            .finally(() => {
              this.item.classList.remove("upload-progress");
              this.item.classList.add("upload-end");
            });
        } else if (this.controller._form) {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = this.controller.fileInputTarget.name;
          this.controller._form.appendChild(hiddenField);
          this.controller._files_loading--;
          this.item.classList.remove("upload-progress");
          if (this.controller._files_loading <= 0) {
            this.controller._formSubmitButton.disabled = false;
          }
          if (this.controller.acceptsMultipleFilesValue === false) {
            this.controller.fileButtonTarget.disabled = true;
          }
          this.controller.uploadedFiles.push({ file: file, signed_id: blob.signed_id });
        }
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    this.item.classList.add("upload-progress");
    this.item.querySelector(".progress-bar").style.width = `${(event.loaded / event.total) * 100}%`;
    if (event.loaded >= event.total) {
    }
  }
}
