import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "input"]
  static values = {value: String};

  initialize() {
    this.onClick = this.onClick.bind(this)
  }

  connect() {
    this.buttonTarget.addEventListener("click", this.onClick)
    // Initial value
    const val = this.inputTarget.value
    if (val == "") {
      this.buttonTarget.classList.remove("checked")
    } else {
      this.buttonTarget.classList.add("checked")
    }
  }

  disconnect() {
    this.buttonTarget.removeEventListener("click", this.onClick)
  }

  onClick(e) {
    const val = this.inputTarget.value
    if (val == "") {
      this.inputTarget.value = this.valueValue
      this.buttonTarget.classList.add("checked")
    } else {
      this.inputTarget.value = ""
      this.buttonTarget.classList.remove("checked")
    }
  }
}
