import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["tenantAdminCommentInput", "landlordCommentInput"]

  connect() {
  }
  generateLandlordComment() {
    let text = "Bonjour,\n"
    this.tenantAdminCommentInputTargets.forEach(input => {
      text += input.value
      text += "\n"
    })
    text += "Qu'en pensez-vous ?\n"
    this.landlordCommentInputTarget.value = text
  }

}