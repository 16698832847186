import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previewImg", "previewIframe", "currentIndex", "btnPrev", "btnNext"];

  _files = [];
  _currentIndex = 0;
  _modalController = null;
  handleShortKeys = this.handleShortKeys.bind(this);
  touchStart = this.touchStart.bind(this);
  touchEnd = this.touchEnd.bind(this);
  touchstartX = 0;
  touchendX = 0;

  initialize() {
    this._modalController = this.element.closest("[data-controller='MBA--modal--component']")["MBA--modal--component"];
  }

  connect() {
    // Load Files
    this._files = JSON.parse(this.element.dataset.files);

    const togglers = document.querySelectorAll(`[data-gallery="${this.element.id}"][data-toggle="gallery"]`);
    for (let i = 0; i < togglers.length; i++) {
      const toggler = togglers[i];
      const fileId = toggler.dataset.fileId;
      toggler.addEventListener("click", (e) => {
        this.open(e, fileId);
      });
    }
  }

  checkDirection(e) {
    if (this.touchendX < this.touchstartX)
      this.next(e);
    if (this.touchendX > this.touchstartX)
      this.prev(e);
  }

  displayFile(e, fileId = null) {
    if (e && !fileId) {
      fileId = e.currentTarget.dataset.fileId;
    }
    const file = this._files.find((f) => f.id == fileId) || this._files[0];

    if (!file) return false;

    // Check file type
    let type = null;
    if (file.content_type.substr(0, 6) == "image/") type = "image";
    else if (file.content_type == "application/pdf") type = "pdf";
    else if (file.content_type == "vr") type = "vr";

    this.previewImgTarget.style.display = "none";
    this.previewIframeTarget.style.display = "none";

    this.previewImgTarget.src = "";
    this.previewIframeTarget.src = "";

    switch (type) {
      case "image":
        this.previewImgTarget.src = file.url;
        this.previewImgTarget.style.display = "flex";
        break;
      case "pdf":
      case "vr":
        this.previewIframeTarget.src = file.url;
        this.previewIframeTarget.style.display = "flex";
        break;
    }

    this._currentIndex = this._files.findIndex((f) => f.id == fileId);
    this.currentIndexTarget.innerText = this._currentIndex + 1;

    if (this._currentIndex >= this._files.length - 1) {
      this.btnNextTargets.forEach((t) => {
        t.setAttribute("disabled", true);
      });
    } else {
      this.btnNextTargets.forEach((t) => {
        t.removeAttribute("disabled");
      });
    }

    if (this._currentIndex <= 0) {
      this.btnPrevTargets.forEach((t) => {
        t.setAttribute("disabled", true);
      });
    } else {
      this.btnPrevTargets.forEach((t) => {
        t.removeAttribute("disabled", true);
      });
    }
  }

  prev(e) {
    e.stopPropagation();
    if (this._currentIndex <= 0) return false;
    this.displayFile(null, this._files[this._currentIndex - 1].id);
  }

  next(e) {
    e.stopPropagation();
    if (this._currentIndex >= this._files.length - 1) return false;
    this.displayFile(null, this._files[this._currentIndex + 1].id);
  }

  handleShortKeys(event) {
    switch (event.key) {
      case "ArrowLeft":
        this.prev(event);
        break;
      case "ArrowRight":
        this.next(event);
        break;
    }
    return;
  }

  touchStart(e) {
    this.touchstartX = e.changedTouches[0].screenX
  }

  touchEnd(e) {
    this.touchendX = e.changedTouches[0].screenX
    this.checkDirection(e)
  }

  open(e = null, fileId = null) {
    if (e) e.preventDefault();
    if (this._modalController) {
      this._modalController.open();
    }
    // Open specific file when opening gallery
    this.displayFile(null, fileId);
    window.addEventListener("keydown", this.handleShortKeys);
    document.addEventListener('touchstart', this.touchStart);
    document.addEventListener('touchend', this.touchEnd);
  }

  close(e = null) {
    window.removeEventListener("keydown", this.handleShortKeys);
    document.removeEventListener("touchstart", this.touchStart);
    document.removeEventListener("touchend", this.touchEnd);
  }
}
