import {Controller} from "@hotwired/stimulus"
import {DirectUpload} from "@rails/activestorage";
import {template} from "lodash";

export default class extends Controller {

  static targets = ["list", "dropzone", "fileTemplate"]

  static values = {directUploadUrl: String, uploadUrl: String, deleteUrl: String, filetypeIcons: Object}

  form = null
  files = []
  uploadingFiles = []
  uploadedFiles = [];
  #input = null

  #fileTemplate = null
  allowedTypesValue = ['*']

  initialize() {
    this.onDrop = this.onDrop.bind(this)
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDragOver = this.onDragOver.bind(this)

    this.#input = this.element.querySelector("input[type='file']");
    this.onInputChange = this.onInputChange.bind(this)
    this.clearFileInputValue = this.clearFileInputValue.bind(this)
    this.openDialog = this.openDialog.bind(this)
    this.form = this.element.closest("form")
  }

  get input() {
    return this.#input
  }

  connect() {
    this.#fileTemplate = template(this.fileTemplateTarget.innerHTML);

    this.#input.addEventListener("change", this.onInputChange)
    this.form.addEventListener("reset", this.clearFileInputValue)

    if (this.hasDropzoneTarget) {
      this.dropzoneTarget.addEventListener("drop", this.onDrop);
      this.dropzoneTarget.addEventListener("dragenter", this.onDragEnter);
      this.dropzoneTarget.addEventListener("dragleave", this.onDragLeave);
      this.dropzoneTarget.addEventListener("dragover", this.onDragOver);
    }
  }

  disconnect() {
    this.#input.removeEventListener("change", this.onInputChange)
    this.form.removeEventListener("reset", this.clearFileInputValue)

    if (this.hasDropzoneTarget) {
      this.dropzoneTarget.removeEventListener("drop", this.onDrop);
      this.dropzoneTarget.removeEventListener("dragenter", this.onDragEnter);
      this.dropzoneTarget.removeEventListener("dragleave", this.onDragLeave);
      this.dropzoneTarget.removeEventListener("dragover", this.onDragOver);
    }
  }

  openDialog(e) {
    e.preventDefault()
    e.stopPropagation()
    this.#input.click()
  }

  clearFileInputValue() {
    this.#input.value = null
    this.files = []
    this.updateFilePreview()
  }

  updateFilePreview() {
    this.listTarget.innerHTML = ""

    this.files.forEach((file) => {
      let preview = document.createElement("div")
      preview.classList.add("file-preview")
      preview.innerHTML = file.name

      let deleteBtn = document.createElement("div")
      deleteBtn.classList.add("delete-btn")
      deleteBtn.innerHTML = "<svg class='w-4 h-4' xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\">\n" +
        "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M6 18L18 6M6 6l12 12\" />\n" +
        "</svg>"
      deleteBtn.addEventListener("click", ((e) => {
        this.deleteFile(file)
      }).bind(this))
      preview.append(deleteBtn)
      this.listTarget.append(preview)
    })
  }

  deleteFile(e) {
    const item = e.currentTarget.closest(".oq-file-item");

    const signed_id = item.dataset.signedId;

    const statusText = item.querySelector(".status-text");
    statusText.innerHTML = "Suppression en cours...";
    item.classList.add("deleting");

    if (this.deleteUrlValue) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

      fetch(this.deleteUrlValue, {
        method: "delete",
        body: JSON.stringify({signed_id: signed_id}),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        credentials: "same-origin",
      })
        .then((response) => {
          let isTurbo = response.headers.get("Content-Type").includes("turbo-stream");
          if (isTurbo) {
            return response.text().then((d) => ({
              turboStream: true,
              data: d,
            }));
          } else {
            try {
              return response.json().then((d) => ({
                turboStream: false,
                data: d,
              }));
            } catch (e) {
              return false;
            }
          }
        })
        .then((data) => {
          if (data.turboStream) {
            document.body.insertAdjacentHTML("beforeend", data.data);
          }
          statusText.innerHTML = "";
          item.remove();
          this.removeFileForItem(item);
          const input = this.form.querySelector(`input[value="${signed_id}"]`);
          if (input) {
            input.remove();
          }
          if (this.acceptsMultipleFilesValue === false) {
            let uploadedFiles = this.form.querySelectorAll(`input[name="${this.#input.name}"][type="hidden"]`);
            if (uploadedFiles.length == 0 || this.files.length == 0) {
              this.fileButtonTarget.disabled = false;
            }
          }
        });
    } else {
      item.remove();
    }
    this.removeFileForItem(item);
    this.removeUploadedFileForItem(item);
    if (this.files.length === 0 && this.uploadedFiles.length === 0 && this.formSubmitButton && this._disable_if_empty === "true") {
      this.formSubmitButton.disabled = true;
    }
  }

  removeFileForItem(item) {
    const index = this.files.findIndex((f) => f.signed_id === item.signed_id);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  removeUploadedFileForItem(item) {
    let index = -1;
    if (item instanceof Element) {
      index = this.uploadedFiles.findIndex((f) => f.signed_id === item.dataset.signedId);
    } else {
      index = this.uploadedFiles.findIndex((f) => f.signed_id === item.signed_id);
    }
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  onDragEnter(e) {
    e.preventDefault()
    this.dropzoneTarget.classList.add("dragover");
  }

  onDragLeave(e) {
    e.preventDefault()
    this.dropzoneTarget.classList.remove("dragover");
  }

  onDragOver(e) {
    e.preventDefault()
  }

  onDrop(e) {
    e.preventDefault();
    this.dropzoneTarget.classList.remove("dragover");
    this.#input.files = e.dataTransfer.files;
    if ("createEvent" in document) {
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      this.#input.dispatchEvent(evt);
    } else {
      this.#input.fireEvent("onchange");
    }
  }

  uploadFile(file) {
    const url = this.directUploadUrlValue;
    let uploader = new Uploader(file, url, this);
  }

  onInputChange(e) {
    if (!this.#input.files.length) return false;

    e.preventDefault()
    e.stopPropagation()
    Array.from(this.#input.files).forEach((file) => this.uploadFile(file));

    // clear file input value
    this.#input.value = null
  }

  createListItem(file) {
    const itemData = {
      signedId: typeof file.signed_id !== "undefined" && file.signed_id ? file.signed_id : "",
      publicUid: typeof file.public_uid !== "undefined" && file.public_uid ? file.public_uid : "",
      filename: file.name,
      filesize: this.humanFileSize(file.size, true),
      id: file.id,
      src: "",
    };
    const item = this.createElementFromHTML(this.#fileTemplate(itemData));
    return item;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }


  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' o';
    }

    const units = si
      ? ['ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }
}


class Uploader {
  constructor(file, url, controller) {
    this.upload = new DirectUpload(file, url, this);
    this.controller = controller;

    this.item = this.controller.createListItem(file);
    this.controller.listTarget.appendChild(this.item);

    this.controller._files_loading++;
    this.controller.uploadingFiles.push({
      file: file,
      xhrUpload: this.xhrUpload,
    })
    if (this.controller._formSubmitButton) this.controller._formSubmitButton.disabled = true;
    this.item.classList.add("uploading")
    const statusText = this.item.querySelector(".status-text");
    statusText.innerHTML = "Envoi en cours...";

    // Check mime type
    if (!this.controller.allowedTypesValue.includes(file.type) && !this.controller.allowedTypesValue.includes("*")) {
      statusText.innerHTML = "Format de fichier non accepté";
      this.item.classList.add("error");
      this.controller._files_loading--;
      if (this.controller._formSubmitButton && this.controller._disable_if_empty === 'false') {
        this.controller._formSubmitButton.disabled = false;
      }
      return false;
    }

    // Check file size
    if (this.controller.maxFileSizeValue && file.size > this.controller.maxFileSizeValue) {
      statusText.innerHTML = "Le fichier est trop volumineux";
      this.item.classList.add("error");
      this.controller._files_loading--;
      if (this.controller._formSubmitButton && this.controller._disable_if_empty === 'false') {
        this.controller._formSubmitButton.disabled = false;
      }
      return false;
    }

    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
        this.item.classList.add("error");
        statusText.innerHTML = "Une erreur s'est produite";
        this.item.classList.remove("uploading");
        this.item.classList.add("upload-end");
      } else {
        this.item.dataset.signedId = blob.signed_id;
        statusText.innerHTML = "";
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content

        if (controller.uploadUrlValue) {
          // Post the created attachment
          fetch(controller.uploadUrlValue, {
            method: "post",
            body: JSON.stringify({file: blob}),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            credentials: "same-origin",
          })
            .then((response) => {
              let isTurbo = (response.headers.get("Content-Type") || []).includes("turbo-stream");
              if (isTurbo) {
                return response.text().then((d) => ({
                  turboStream: true,
                  data: d,
                }));
              } else {
                try {
                  return response.json().then((d) => ({
                    turboStream: false,
                    data: d,
                  }));
                } catch (e) {
                  return false
                }
              }
            })
            .then((data) => {
              this.controller._files_loading--;
              if (this.controller._files_loading <= 0 && this.controller._formSubmitButton) {
                this.controller._formSubmitButton.disabled = false;
              }
              if (data.data.error) {
                this.item.classList.add("error");
                statusText.innerHTML = data.data.error;
              } else {
                statusText.innerHTML = "";
                this.item.dataset.publicUid = data.data.public_uid;
                if (data.turboStream) {
                  document.body.insertAdjacentHTML("beforeend", data.data);
                } else {
                  Turbo.visit(window.location.toString(), {action: "replace"});
                }
              }
            })
            .catch((error) => {
              this.item.classList.add("error");
              statusText.innerHTML = "Une erreur s'est produite";
              if (this._disable_if_empty === 'false') {
                this.controller._formSubmitButton.disabled = false;
              }
            })
            .finally(() => {
              this.item.classList.remove("uploading");
              this.item.classList.add("upload-end");
            });
        } else if (this.controller._form) {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = this.controller.input.name;
          this.controller._form.appendChild(hiddenField);
        } else if (this.controller.form) {

          var reader = new FileReader();

          reader.onload = (event) => {
            const filetype = file.type
            const imgPreview = this.item.querySelector(".img-preview")
            const filetypeIcon = this.item.querySelector(".filetype-icon")
            if (filetype.startsWith("image")) {
              const previewUrl = event.target.result
              if (imgPreview) {
                imgPreview.setAttribute("src", previewUrl)
                imgPreview.classList.remove("hidden")
                filetypeIcon.classList.add("hidden")
              }
            } else {
              const extension = filetype.split("/")[1]
              const icon = this.controller.filetypeIconsValue[extension] || this.controller.filetypeIconsValue["default"]
              if (icon) {
                imgPreview.classList.add("hidden")
                filetypeIcon.setAttribute("src", icon)
                filetypeIcon.classList.remove("hidden")
              }
            }
          }

          reader.readAsDataURL(file);

          this.item.querySelector("input").value = blob.signed_id
          this.controller._files_loading--;
          this.item.classList.remove("uploading");
          if (this.controller._files_loading <= 0) {
            this.controller._formSubmitButton.disabled = false;
          }
          if (this.controller.acceptsMultipleFilesValue === false) {
            this.controller.fileButtonTarget.disabled = true;
          }
          this.controller.uploadedFiles.push({file: file, signed_id: blob.signed_id});
        }
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    this.xhr = request
    this.xhr.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
    this.xhr.upload.addEventListener("abort", (event) => this.directUploadAborted(event));

    // Bind cancel upload button
    // const cancelUploadBtn = this.item.querySelector(".cancel-upload-btn")
    // cancelUploadBtn.addEventListener("click", () => {
    //   this.xhr.abort();
    // })
  }

  directUploadAborted(event) {
  }

  directUploadWillCreateBlobWithXHR(request) {

  }

  directUploadDidProgress(event) {
    this.item.classList.add("uploading");
    this.item.querySelector(".progress-bar .progress").style.width = `${(event.loaded / event.total) * 100}%`;
    if (event.loaded >= event.total) {
      this.item.classList.remove("uploading");
    }
  }
}
