import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container", "addButton"];

  addNew() {
    let div = document.createElement("div")
    div.className = 'nested-form-container'
    div.innerHTML = this.templateTarget.innerHTML.replace(/CHILD_INDEX/g, (Date.now()).toString());
    this.containerTarget.appendChild(div)
    this.triggerChange()
  }

  delete(e) {
    const form = e.currentTarget.closest(".nested-form-container")
    form.remove()
    this.triggerChange()
  }

  triggerChange() {
    const event = new Event('change');
    this.element.dispatchEvent(event);
  }
}
