import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initVisibility()
  }

  initVisibility() {
    const blocPerms = this.element.querySelectorAll(".bloc-perm")
    blocPerms.forEach(blocPerm => {
      const toggle = blocPerm.querySelector(".oq-toggle .oq-input")
      this.toggleBlocPerm(blocPerm, toggle.checked)
    })
  }

  toggleBlocPerm(blocPerm, checked) {
    const displays = blocPerm.querySelectorAll(".display-checked")
    if (checked) {
      blocPerm.classList.add("bg-brand-50")
      blocPerm.classList.add("border-brand")
      blocPerm.classList.remove("bg-gray-50")
      displays.forEach(display => {
        display.classList.remove("hidden")
      })
    } else {
      blocPerm.classList.remove("bg-brand-50")
      blocPerm.classList.remove("border-brand")
      blocPerm.classList.add("bg-gray-50")
      displays.forEach(display => {
        display.classList.add("hidden")
      })
    }
  }

  togglePermission(e) {
    const toggle = e.currentTarget
    const blocPerm = toggle.closest(".bloc-perm")
    this.toggleBlocPerm(blocPerm, toggle.checked)
  }
}
