import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["displayValue", "searchInput", "menuItem"]
    static values = { initial: String }

    _input = null

    connect() {
        this._input = this.element.getElementsByTagName('select')[0]
        this._input.style.display = 'none'
        const initialValue = this._input.value || this.initialValue
        this.element.querySelectorAll('.menu__item').forEach(((item) => {
            const value = item.dataset.value
            if (value == initialValue)
                this.select_item(item)
            item.addEventListener('click', ((e) => {
                e.preventDefault()
                this.select_item(item)
            }).bind(this))
        }).bind(this))

        if (this.hasSearchInputTarget) {
            this.searchInputTarget.addEventListener('input', ((e) => this.search(e.currentTarget.value)).bind(this))
        }
    }

    select_item(item) {
        this._input.value = item.dataset.value
        this.displayValueTarget.innerHTML = item.innerHTML
        let dropdown = item.closest('.dropdown')['dropdown--component']
        if (dropdown)
            dropdown.close()
    }

    search(query) {
        let regex = '.*'
        query = query.replace(/\s/g, '').replace(/[^\w\s]/gi, '');
        for (let i = 0; i < query.length; i++)
            regex += `${query[i]}.*`
        regex = new RegExp(regex, 'gi')
        this.menuItemTargets.forEach((menuItem) => {
            if (menuItem.dataset.label.match(regex))
                menuItem.style.removeProperty('display')
            else
                menuItem.style.display = 'none'
        })
    }

}
