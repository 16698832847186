import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["input", "ibanInput"];

  initialize() {
    this.on_input = this.on_input.bind(this)
    this.on_keydown = this.on_keydown.bind(this)
    this.on_focus = this.on_focus.bind(this)
    this.on_paste = this.on_paste.bind(this)
  }

  connect() {
    this.element[this.identifier] = this;

    this.updateInputStyle();
    if (this.inputTarget.value) {
      this.update_fields(this.inputTarget.value);
    }
    this.ibanInputTargets.forEach((input) => {
      input.addEventListener("input", (e) => this.on_input(e, input));
      input.addEventListener("keydown", (e) => this.on_keydown(e, input));
      input.addEventListener("focus", (e) => this.on_focus(e, input));
      input.addEventListener("paste", (e) => this.on_paste(e, input));
    });
  }

  disconnect() {
    this.ibanInputTargets.forEach((input) => {
      input.removeEventListener("input", this.on_input);
      input.removeEventListener("keydown", this.on_keydown);
      input.removeEventListener("focus", this.on_focus);
      input.removeEventListener("paste", this.on_paste);
    });
  }


  on_focus(e, input) {
    input.select();
  }

  on_input(e, input) {
    const index = this.ibanInputTargets.findIndex((i) => i == input);
    if (input.value.length >= parseInt(input.getAttribute("maxlength"))) {
      // Focus next input
      const nextInput = index < this.ibanInputTargets.length - 1 ? this.ibanInputTargets[index + 1] : null;
      if (nextInput) nextInput.focus();
    }
  }

  on_keydown(e, input) {
    if (e.key === "Backspace") {
      const index = this.ibanInputTargets.findIndex((i) => i == input);
      if (input.value.length == 0) {
        e.preventDefault();
        input.value = "";

        const prevInput = index > 0 ? this.ibanInputTargets[index - 1] : null;
        if (prevInput) prevInput.focus();
      }
    }
  }

  on_paste(e) {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    paste = paste.replace(/\s/g, "");
    this.update_fields(paste);
    this.update();
  }

  update_fields(value) {
    const fields_values = value.match(/.{1,4}/g);
    this.ibanInputTargets.forEach(
      ((input, index) => {
        const val = fields_values[index] === undefined ? "" : fields_values[index];
        input.value = val;
      }).bind(this)
    );
  }

  update() {
    let iban = "";
    this.ibanInputTargets.forEach((input) => {
      iban += input.value;
    });
    this.inputTarget.value = iban.toUpperCase();
  }

  updateInputStyle() {
    if (this.inputTarget.classList.contains("is-danger")) {
      this.ibanInputTargets.forEach((input) => {
        input.classList.add("is-danger");
      });
    }
  }

  setValue(value) {
    this.inputTarget.value = value
    this.update_fields(value)
  }
}
