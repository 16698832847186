import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fromInput", "toInput"];
  static values = {min: Number, max: Number}

  changeFrom() {
    if (parseInt(this.fromInputTarget.value) > parseInt(this.toInputTarget.value)) {
      this.toInputTarget.value = this.fromInputTarget.value;
      this.changeTo();
    }
    if (this.hasMinValue && (parseInt(this.fromInputTarget.value) < this.minValue))
      this.fromInputTarget.value = this.minValue;
    if (this.hasMaxValue && (parseInt(this.fromInputTarget.value) > this.maxValue))
      this.fromInputTarget.value = this.maxValue;
  }

  changeTo() {
    if (parseInt(this.toInputTarget.value) < parseInt(this.fromInputTarget.value)) {
      this.fromInputTarget.value = this.toInputTarget.value;
      this.changeFrom();
    }
    if (this.hasMinValue && (parseInt(this.toInputTarget.value) < this.minValue))
      this.toInputTarget.value = this.minValue;
    if (this.hasMaxValue && (parseInt(this.toInputTarget.value) > this.maxValue))
      this.toInputTarget.value = this.maxValue;
  }
}