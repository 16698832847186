import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  static values = {value: String, successContent: String}

  connect() {
    super.connect()
    const input = document.createElement("input");
    input.type = "hidden";
    input.value = this.hasValueValue ? this.valueValue : "";
    input.setAttribute("data-clipboard--component-target", "source");
    this.element.appendChild(input);
  }
  copy(e) {
    try {
      super.copy(e)
    } catch (err) {
    }
  }
}