import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "select", "list", "input"];
  _selectedOptions = [];
  // Test si mobile/tablet ou desktop
  _isMobile = window.screen.width < 768;
  _modalController = null;

  connect() {
    this.input_placeholder = this.inputTarget.placeholder;
    this.initSelectedValues();
    if (!this._isMobile) {
      window.addEventListener("resize", this.updateSelectWrapper.bind(this));
    }
  }

  disconnect() {
    window.removeEventListener("resize", window.onWindowResize);
  }

  initSelectedValues() {
    this.selectTarget.querySelectorAll(`option[selected="selected"]`).forEach((option) => {
      this.selectOption(option.value);
    });
    this.updateSelectWrapper();
  }

  toggleOption(e) {
    const el = e.currentTarget;
    const value = el.dataset.value;
    const index = this._selectedOptions.indexOf(value);

    const listItem = this.listTarget.querySelector(`.list__item[data-value="${value}"]`);

    if (index > -1) {
      this._selectedOptions.splice(index, 1);
      this.selectTarget.querySelector(`option[value="${value}"]`).selected = false;
      listItem.classList.remove("list__item--checked");
    } else {
      this.selectOption(value);
    }
    this.inputTarget.value = "";
    if (!this._isMobile) this.inputTarget.focus();
    // Trigger input event to reset list item displays
    const event = document.createEvent("Event");
    event.initEvent("input", true, true);

    this.inputTarget.dispatchEvent(event);
    this.updateSelectWrapper();
  }

  selectOption(value) {
    const listItem = this.listTarget.querySelector(`.list__item[data-value="${value}"]`);
    this._selectedOptions.push(value);
    this.selectTarget.querySelector(`option[value="${value}"]`).selected = true;
    listItem.classList.add("list__item--checked");
  }

  search(e) {
    const query = new RegExp(e.currentTarget.value, "i");
    this.listTarget.querySelectorAll(".list__item").forEach((el) => {
      if (el.innerText.match(query)) {
        el.style.display = "flex";
      } else {
        el.style.display = "none";
      }
    });
  }

  updateSelectWrapper() {
    // Si options sélectionnées, on cache les inputs
    if (this._isMobile) {
      if (this._selectedOptions.length > 0) {
        this.inputTargets.forEach((input) => {
          input.classList.add("hidden");
        });
      } else {
        this.inputTargets.forEach((input) => {
          input.classList.remove("hidden");
        });
      }
    }

    const selectionWrapper = this.element.querySelector(".selection_wrapper");
    const inputWrapper = this.element.querySelector(".input_wrapper");

    // Clear options
    this.wrapperTarget.querySelectorAll("div.multiselect__option").forEach((el) => el.remove());
    this.inputTarget.placeholder = this.input_placeholder;
    if (this._isMobile) {
      selectionWrapper.querySelectorAll("div.multiselect__option-mobile")?.forEach((el) => el.remove());
      inputWrapper.querySelectorAll("div.multiselect__option-mobile")?.forEach((el) => el.remove());
    } else {
      let counter_wrapper = this.wrapperTarget.querySelector('.multiselect__option__counter');
      if (counter_wrapper) {
        counter_wrapper.remove();
      }
    }

    this._selectedOptions
      .map((value) => {
        const item = document.createElement("div");
        item.classList.add("multiselect__option");
        if (this._isMobile) item.classList.add("multiselect__option-mobile");
        const option = this.selectTarget.querySelector(`option[value="${value}"]`);
        item.innerText = option.innerText;
        const deleteBtn = document.createElement("span");
        deleteBtn.className = "option__delete-btn";
        deleteBtn.innerHTML =
          '\
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> \
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /> \
              </svg> ';
        deleteBtn.dataset.value = value;
        deleteBtn.addEventListener("click", this.toggleOption.bind(this));
        item.appendChild(deleteBtn);
        return item;
      })
      .reverse()
      .forEach((item, index) => {
        if (this._isMobile) {
          const item2 = item.cloneNode(true);
          const item3 = item.cloneNode(true);
          [item2, item3].map((i) => {
            i.lastChild.addEventListener("click", this.toggleOption.bind(this));
          });
          if (index === 0) {
            this.wrapperTarget.prepend(item);
            inputWrapper.prepend(item2);
          } else if (index === 1) {
            const elem = document.createElement("div");
            elem.classList.add("multiselect__option", "multiselect__option-mobile", "multiselect__option__counter");
            elem.innerText = `+ ${this._selectedOptions.length - 1}`;
            inputWrapper.append(elem);
            const elem2 = elem.cloneNode(true);
            this.wrapperTarget.append(elem2);
          }
          selectionWrapper.prepend(item3);
        } else {
          this.wrapperTarget.prepend(item);
          this.inputTarget.placeholder = "";
        }
      });
      this.updateSelectionForOverflow();
  }

  updateSelectionForOverflow() {
    let parent = this.wrapperTarget.closest('.multiselect-container');
    if (parent && (this.wrapperTarget.clientHeight > parent.clientHeight || this.wrapperTarget.clientWidth > parent.clientWidth)) {
      let multiselect_options = this.wrapperTarget.querySelectorAll('.multiselect__option');
      multiselect_options[0].remove();
      let counter = this._selectedOptions.length - this.wrapperTarget.querySelectorAll('.multiselect__option').length;
      if (this.wrapperTarget.querySelector('.multiselect__option__counter')) {
        this.wrapperTarget.querySelector('.multiselect__option__counter').innerText = `+ ${counter}`;
      } else {
        const elem = document.createElement("div");
        elem.classList.add("multiselect__option-mobile", "multiselect__option__counter");
        elem.innerText = `+ ${counter}`;
        this.wrapperTarget.append(elem);
      }
      this.updateSelectionForOverflow();
    }
  }
}
