import {Controller} from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["input"]

  #enabled = false

  connect() {
    this.#enabled = (this.inputTarget.value == "1")
    if (this.#enabled) {
      this.element.classList.add("enabled")
    } else {
      this.element.classList.remove("enabled")
    }
  }

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.#enabled) {
      this.element.classList.remove("enabled")
    } else {
      this.element.classList.add("enabled")
    }
    this.#enabled = !this.#enabled
    this.inputTarget.value = this.#enabled ? 1 : 0
    this.inputTarget.checked = this.#enabled
    const event = new Event('change');
    this.inputTarget.dispatchEvent(event);
  }
}
