import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["input", "codeInput"];

  initialize() {
    this.onInput = this.onInput.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  connect() {
    this.element[this.identifier] = this;

    this.updateInputStyle();
    if (this.inputTarget.value) {
      this.updateFields(this.inputTarget.value);
    }

    this.codeInputTargets.forEach((input) => {
      input.addEventListener("input", (e) => this.onInput(e, input));
      input.addEventListener("keydown", (e) => this.onKeydown(e, input));
      input.addEventListener("focus", (e) => this.onFocus(e, input));
      input.addEventListener("paste", (e) => this.onPaste(e, input));
    });
  }

  disconnect() {
    this.codeInputTargets.forEach(
      ((input) => {
        input.removeEventListener("input", this.onInput);
        input.removeEventListener("keydown", this.onInput);
        input.removeEventListener("focus", this.onInput);
        input.removeEventListener("paste", this.onInput);
      })
    );
  }

  onFocus(e, input) {
    input.select();
  }

  onInput(e, input) {
    const index = this.codeInputTargets.findIndex((i) => i == input);

    if (input.value.length >= parseInt(input.getAttribute("maxlength"))) {
      // Focus next input
      const nextInput = index < this.codeInputTargets.length - 1 ? this.codeInputTargets[index + 1] : null;
      if (nextInput) nextInput.focus();
    }
  }

  onKeydown(e, input) {
    if (e.key === "Backspace") {
      const index = this.codeInputTargets.findIndex((i) => i == input);
      if (input.value.length == 0) {
        e.preventDefault();
        input.value = "";

        const prevInput = index > 0 ? this.codeInputTargets[index - 1] : null;
        if (prevInput) prevInput.focus();
      }
    }
  }

  onPaste(e) {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    paste = paste.replace(/\s/g, "");
    this.updateFields(paste);
    this.update();
  }

  updateFields(value) {
    const fieldsValues = value.split("");
    this.codeInputTargets.forEach(
      ((input, index) => {
        const val = fieldsValues[index] === undefined ? "" : fieldsValues[index];
        input.value = val;
      }).bind(this)
    );
  }

  update() {
    let code = "";
    this.codeInputTargets.forEach((input) => {
      code += input.value;
    });
    this.inputTarget.value = code;
  }

  updateInputStyle() {
    if (this.inputTarget.classList.contains("is-danger")) {
      this.codeInputTargets.forEach((input) => {
        input.classList.add("is-danger");
      });
    }
  }

  setValue(value) {
    this.inputTarget.value = value;
    this.updateFields(value);
  }
}
