import { Controller } from "@hotwired/stimulus";

import "stylesheets/app/catalog/search.scss";

export default class extends Controller {
  initialize() {
    this.hideAllActionsElem = this.hideAllActionsElem.bind(this);
  }

  connect() {
    document.addEventListener("click", this.hideAllActionsElem);
  }

  disconnect() {
    document.removeEventListener("click", this.hideAllActionsElem);
  }

  displayActions(event) {
    const elem = event.target;
    this.removeOpenActionsElem();

    let div = document.createElement("div");
    div.classList.add("event-actions-wrapper");
    // Show link
    let show_link = document.createElement("a");
    show_link.appendChild(document.createTextNode("👁️"));
    show_link.title = "Voir l'évènement"
    show_link.href = elem.dataset.showEventPath;
    div.append(show_link);
    // Edit link
    if (elem.dataset.editEventPath !== '' && elem.dataset.editEventPath !== null) {
      let edit_link = document.createElement("a");
      edit_link.appendChild(document.createTextNode("✏️"));
      edit_link.title = "Modifier l'évènement"
      edit_link.href = elem.dataset.editEventPath;
      div.append(edit_link);
    }
    // Delete link
    if (elem.dataset.destroyEventPath !== '' && elem.dataset.destroyEventPath !== null) {
      let destroy_link = document.createElement('div');
      destroy_link.innerHTML = elem.dataset.destroyEventPath.trim();
      div.append(destroy_link);
    }
    elem.classList.add('active');
    elem.append(div);
  }

  removeOpenActionsElem() {
    document.querySelectorAll('.event-actions-wrapper').forEach((wrapperElem) => {
      wrapperElem.remove();
    });
  }

  hideAllActionsElem(e) {
    if (e.target === 'undefined' || e.target.classList.contains('event'))
      return;
    this.removeOpenActionsElem();
  }
}
