import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "searchForm",
    "searchInput",
    "resultsList",
  ];

  static values = {
    searchUrl: String,
  };

  #selectedResult = null;
  #selectedResultIndex = null;

  initialize() {
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
  }

  connect() {
    this.searchInputTarget.addEventListener("keydown", this.onInputKeyDown);
  }

  disconnect() {
    this.searchInputTarget.removeEventListener("keydown", this.onInputKeyDown);
  }

  instantSearch() {
    this.searchFormTarget.requestSubmit();
  }

  onInputKeyDown(e) {
    const {key} = e;
    if (key == "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (this.#selectedResult) {
        this.#selectedResult.click();
      }
    }
    if (key == "ArrowDown") {
      this.selectNextResult(e);
    }
    if (key == "ArrowUp") {
      this.selectPrevResult(e);
    }
  }

  getNextResult() {

  }

  selectPrevResult(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!this.hasResultsListTarget) {
      return;
    }
    const results = this.resultsListTarget.querySelectorAll('.result');
    if (!results.length) {
      return;
    }
    if (!this.#selectedResult) {
      this.#selectedResultIndex = results.length - 1;
      this.#selectedResult = results[this.#selectedResultIndex];
    } else {
      this.#selectedResultIndex = this.#selectedResultIndex > 0 ? this.#selectedResultIndex - 1 : results.length - 1;
      this.#selectedResult = results[this.#selectedResultIndex];
    }
    if (this.#selectedResult) {
      results.forEach(r => {
        r.classList.remove("selected");
      });
      this.#selectedResult.classList.add("selected");
    }
  }

  selectNextResult(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!this.hasResultsListTarget) {
      return;
    }
    const results = this.resultsListTarget.querySelectorAll('.result');
    if (!results.length) {
      return;
    }
    if (!this.#selectedResult) {
      this.#selectedResultIndex = 0;
      this.#selectedResult = results[this.#selectedResultIndex];
    } else {
      this.#selectedResultIndex = this.#selectedResultIndex < results.length - 1 ? this.#selectedResultIndex + 1 : 0;
      this.#selectedResult = results[this.#selectedResultIndex];
    }
    if (this.#selectedResult) {
      results.forEach(r => {
        r.classList.remove("selected");
      });
      this.#selectedResult.classList.add("selected");
    }
  }
}
