import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["btnPlus", "btnMinus", "value"]
    static values = {min: Number, max: Number}

    _input = null

    connect() {
        this.btnPlusTarget.addEventListener("click", (() => {
            this.plus()
        }).bind(this))
        this.btnMinusTarget.addEventListener("click", (() => {
            this.minus()
        }).bind(this))
        this._input = this.element.getElementsByTagName('input')[0]
        this.update()
    }

    plus() {
        const value = (parseInt(this._input.value) || this.minValue) + 1
        if (value > this.maxValue)
            return false
        this._input.value = value
        this.update()
    }

    minus() {
        const value = (parseInt(this._input.value) || this.minValue) - 1
        if (value < this.minValue)
            return false
        this._input.value = value
        this.update()
    }

    update() {
        const value = parseInt(this._input.value)
        this.valueTarget.innerText = value
        if (value <= this.minValue)
            this.btnMinusTarget.setAttribute('disabled', 'disabled')
        else
            this.btnMinusTarget.removeAttribute('disabled')
        if (value >= this.maxValue)
            this.btnPlusTarget.setAttribute('disabled', 'disabled')
        else
            this.btnPlusTarget.removeAttribute('disabled')
        if (value == 0)
            this.element.classList.add('wrapper--inactive')
        else
            this.element.classList.remove('wrapper--inactive')
    }
}
