import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { activeTab: String, followLink: Boolean };

  connect() {
    this.element.querySelectorAll(`.OQORO-tab__tab`).forEach(
      ((el) => {
        let tab = el.dataset.tab;
        el.addEventListener(
          "click",
          (() => {
            this.selectTab(tab);
            if (this.followLinkValue == true) {
              let url = el.dataset.tabUrl;
              window.location = url;
            }
          }).bind(this)
        );
      }).bind(this)
    );
    if (this.activeTabValue) {
      this.selectTab(this.activeTabValue);
    }
  }

  selectTab(tab) {
    this.element.querySelectorAll(`.OQORO-tab__tab`).forEach((el) => {
      if (el.dataset.tab == tab) {
        el.classList.add("active");
        let url = el.dataset.tabUrl;
        if (url && this.followLinkValue == false) {
          window.history.pushState(null, "", url);
        }
      } else el.classList.remove("active");
    });
    document.querySelectorAll(".OQORO-tab__panel").forEach(
      ((el) => {
        if (el.dataset.tab == tab) el.classList.add("open");
        else el.classList.remove("open");
      }).bind(this)
    );
  }
}
