import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    Turbo.setConfirmMethod((data, element) => {
      let id = "";
      if (element.tagName.toLowerCase() == "form") {
        id = element.querySelector("[data-turbo-confirm]").id
      } else {
        id = element.id;
      }

      let dialog = document.getElementById(`dialog-${id}`);
      let dialogController = dialog["modal--component"];
      dialogController.open();

      return new Promise((resolve, reject) => {
        let committers = dialog.querySelectorAll("[name='commit']");
        committers.forEach((committer) => {
          committer.addEventListener("click", (e) => {
            e.preventDefault();
            resolve(e.currentTarget.value == "confirm");
            dialogController.close();
          });
        });
      });
    });
  }
}
