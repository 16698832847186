import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  lastScrollPosition = 0;

  connect() {
    window.addEventListener("scroll", this.onScroll.bind(this));
  }

  scrollPosition() {
    return window.scrollY;
  }

  onScroll(event) {
    if (this.scrollPosition() < this.lastScrollPosition) {
      this.element.classList.add("scrolled-up");
      this.element.classList.remove("scrolled-down");
    } else if (this.scrollPosition() > this.element.offsetHeight) {
      this.element.classList.add("scrolled-down");
      this.element.classList.remove("scrolled-up");
    }
    this.lastScrollPosition = this.scrollPosition();
  }
}
