import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";
export default class extends Controller {

  initialize() {
    this.toggle = this.toggle.bind(this)
  }

  connect() {
    this.bindTogglers();
    const isOpen = this.element.classList.contains("open") ? "open" : "closed"
    if (Cookies.get('admin-sidebar') != isOpen)
      this.toggle()
  }

  toggle(e) {
    if (e)
      e.preventDefault();
    this.element.classList.toggle("open")
    const isOpen = this.element.classList.contains("open") ? "open" : "closed"
    Cookies.set('admin-sidebar', isOpen, { sameSite: 'strict' })
  }

  bindTogglers() {
    document.querySelectorAll(`[data-toggle="sidebar"]`).forEach(
      ((elem) => {
        elem.addEventListener(
          "click", this.toggle
        );
      })
    );
  }
}
