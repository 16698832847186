import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    setTimeout(() => {
      this.updatePrestations()
    }, 10)
  }

  async updatePrestations() {
    let totalQuantity = 0
    let totalExtraCost = 0
    let totalDuration = 0
    let forfaitCost = 0
    let forfaitTravelExpense = 0

    const prestationForfait = this.element.querySelector(".oq-form-group.prestation_forfait_id .oq-select")["form--collection-select--component"]
    const prestationForfaitId = prestationForfait?.value
    if (prestationForfaitId) {
      const data = await fetch(`/oqoro_admin/prestation_forfaits/${prestationForfaitId}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      }).then(response => response.json())
        .then(data => {
          return data
        })
      forfaitCost = 1 * data.cost
      forfaitTravelExpense = 1 * data.travel_expense
    }
    const blocPrestations = this.element.querySelector(".oq-form-group.intervention_prestations")
    const groups = blocPrestations.querySelectorAll(".nested-form-container")
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      let quantity = group.querySelector("input[name$='[quantity]']").value
      quantity = (!isNaN(quantity) && quantity ? parseInt(quantity) : 0)
      totalQuantity += quantity
      const prestation = group.querySelector(".oq-select")["form--collection-select--component"]
      const prestationId = prestation?.value
      if (prestationId) {
        const data = await fetch(`/oqoro_admin/prestations/${prestationId}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
        }).then(response => response.json())
          .then(data => {
            return data
          })
        totalDuration += (data.duration * quantity)
        totalExtraCost += (data.supply_cost * quantity)
      }
    }
    const label = blocPrestations.querySelector("&> label")
    label.innerHTML = `Prestations (${totalQuantity})<br>- Durée estimée : ${totalDuration} min<br>- Coût extra fournitures : ${totalExtraCost} € TTC<br>- Forfait HT: ${forfaitCost} € (+ frais déplacement: ${forfaitTravelExpense} €)`
  }
}