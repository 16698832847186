import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["omnibar", "searchInput"];

  _filterForm = null;
  _searchInput = null;

  connect() {
    if (!this._filterForm) {
      this._filterForm = this.element.querySelector(".filter_form");
      this.element.querySelector("#q_search_input").style.display = "none";
    }

    if (!this._searchInput) {
      this._searchInput = this._filterForm.querySelector("[name='q[search]']");
    }
    if (!this._searchInput) {
      this.searchInputTarget.classList.add("hidden");
    }
  }

  deleteFilter({params: {fieldName, value}}) {
    const isMultiple = fieldName.endsWith("_in]");
    const selector = isMultiple ? `select[name="${fieldName}[]"]` : `[name="${fieldName}"]`

    const field = this._filterForm.querySelector(selector);

    if (this.clearField(field, value)) {
      this._filterForm.submit();
    }
  }

  clearField(field, value) {
    if (!field)
      return false;
    if (field.tagName == "SELECT") {
      const option = field.querySelector(`option[value="${value}"]`);
      if (!option)
        return false;
      option.removeAttribute("selected");
    } else {
      field.value = "";
    }
    return true;
  }

  searchSubmit(e) {
    e.preventDefault();
    if (this._searchInput) {
      const search = this.searchInputTarget.value;
      this._filterForm.querySelector("[name='q[search]']").value = search;
    }
    this._filterForm.submit();
    return false;
  }

  updateSearchInput(e) {
    if (this._searchInput) {
      this._filterForm.querySelector("[name='q[search]']").value = this.searchInputTarget.value;
    }
  }

  saveFilter(e) {
    if (!this._filterForm)
      return false;
    const data = new FormData(this._filterForm);

    let d = {};
    for (const pair of data.entries()) {
      const key = pair[0];
      const value = pair[1];
      if (!(key.startsWith("q[")) || !value)
        continue;

      if (key.endsWith("[]")) {
        const keyclean = key.substring(2, key.length - 3);
        if (!d[keyclean])
          d[keyclean] = [];
        d[keyclean].push(value);
      } else {
        const keyclean = key.substring(2, key.length - 1);
        d[keyclean] = value;
      }

    }
    const json = JSON.stringify(d);
    document.querySelector("[name='filter[filters]']").value = json;
  }

  selectCustomFilter() {
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "q[custom_filters][]";
    input.value = "1";
    this._filterForm.append(input);
    this._filterForm.submit();
  }
}