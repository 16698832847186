import {Controller} from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {

  static targets = ["draggable", "droppable", "bloc"]
  static values = {updateIndexesUrl: String}

  #currentDraggable
  #currentDroppable
  #cursorX
  #cursorDirection

  #previousDroppable
  #placement

  initialize() {
    this.dragstartHandler = this.dragstartHandler.bind(this)
    this.dragoverHandler = this.dragoverHandler.bind(this)
    this.dragenterHandler = this.dragenterHandler.bind(this)
    this.dragendHandler = this.dragendHandler.bind(this)
  }

  connect() {
    this.draggableTargets.forEach(d => {
      d.addEventListener("dragstart", this.dragstartHandler);
      d.addEventListener("dragend", this.dragendHandler);
    })
    this.droppableTargets.forEach(d => {
      d.addEventListener("dragover", this.dragoverHandler);
      d.addEventListener("dragenter", this.dragenterHandler);
    })
  }

  dragstartHandler(ev) {
    // Add the target element's id to the data transfer object
    ev.dataTransfer.setData("text/plain", ev.target.id);
    ev.dataTransfer.dropEffect = "none";
    this.#currentDraggable = ev.currentTarget
    this.#currentDraggable.classList.add("outline", "outline-blue")
    this.#currentDraggable.style.cursor = 'grabbing'
    this.#placement = null
    this.#previousDroppable = null
    // Disable ghost image
    const transparentPixel = new Image();
    transparentPixel.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    ev.dataTransfer.setDragImage(transparentPixel, 0, 0);
  }

  positionChanged(placement) {
    if (this.#currentDroppable != this.#previousDroppable)
      return true
    if (placement != this.#placement)
      return true
    return false
  }

  dragoverHandler(ev) {
    ev.preventDefault();
    if (!this.#currentDraggable)
      return false;
    ev.dataTransfer.dropEffect = "none";
    const isDraggable = this.draggableTargets.includes(this.#currentDroppable)
    const cursorX = ev.clientX
    const direction = (this.#cursorX == cursorX) ? (this.#cursorDirection || "left") : (this.#cursorX < cursorX ? "right" : "left")
    this.#cursorDirection = direction
    this.#cursorX = cursorX
    let placement = "before"
    if (isDraggable) {
      const dropRect = this.#currentDroppable.getBoundingClientRect();
      if (direction == "left") {
        placement = (cursorX < (dropRect.left + dropRect.width * 4 / 5)) ? "before" : "after"
      } else {
        placement = (cursorX < (dropRect.left + dropRect.width * 1 / 5)) ? "before" : "after"
      }
    }

    if (!this.positionChanged(placement)) {
      return
    }

    if (!isDraggable || placement == "before") {
      if (!(this.#currentDraggable.nextSibling == this.#currentDroppable))
        this.#currentDroppable.before(this.#currentDraggable)
    } else {
      if (!(this.#currentDroppable.nextSibling == this.#currentDraggable))
        this.#currentDroppable.after(this.#currentDraggable)
    }

    this.updateIndexes()

    this.#placement = placement
    this.#previousDroppable = this.#currentDroppable
  }

  dragenterHandler(ev) {
    this.#currentDroppable = ev.currentTarget
  }

  dragendHandler(ev) {
    this.#currentDraggable.style.cursor = 'grab'
    this.#currentDraggable.classList.remove("outline", "outline-blue")

    // Update Request
    this.updateIndexesRequest()

    this.#currentDraggable = null
    this.#currentDroppable = null
  }

  updateIndexes() {
    this.blocTargets.forEach(b => {
      const draggables = b.querySelectorAll(".draggable")
      for (let i = 0; i < draggables.length; i++) {
        const draggable = draggables[i]
        draggable.querySelector(".draggable_index").innerText = i == 0 ? "Image principale" : (i + 1).toString()
      }
    })
  }

  updateIndexesRequest() {
    const json = []
    this.blocTargets.forEach(b => {
      const draggables = b.querySelectorAll(".draggable")
      for (let i = 0; i < draggables.length; i++) {
        const d = draggables[i]
        json.push({
          attachment_id: d.dataset.id,
          attached_to_type: b.dataset.type,
          attached_to_id: b.dataset.id,
          position: i + 1,
        })
      }
    })

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    fetch(this.updateIndexesUrlValue, {
      method: "POST",
      body: JSON.stringify({attachments: json}),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
  }
}
