import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"]

  #form = null;

  initialize() {
    this.next = this.next.bind(this)
    this.#form = new Form(this.element);
    this.validateForm = this.validateForm.bind(this);
    this.element.addEventListener("turbo:submit-end", this.next)
    let actionBtn = document.querySelector('[data-disable-onclick]');
    if (actionBtn) {
      actionBtn.dataset['action'] = "click->form--component#disableSubmitters";
    }
  }

  connect() {
    this.validateForm();

    this.initTabs();
  }

  validateForm() {
    this.#form.validate();
  }

  requestSubmit() {
    this.#form.requestSubmit();
  }

  next(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse
      if (!fetchResponse.response.redirected)
        return
      history.pushState(
        {turbo_frame_history: true},
        "",
        fetchResponse.response.url
      )
      Turbo.visit(fetchResponse.response.url)
    }
  }

  disableSubmitters(e) {
    this.#form.disableSubmitters();
    this.element.submit();
  }

  initTabs() {
    this.tabTargets.forEach(tab => {
      tab.addEventListener("tab--component:connected", this.tabConnected.bind(this));
    })
  }

  tabConnected(e) {
    const tabComponent = e.currentTarget
    const tabController = tabComponent["tab--component"]
    const tabs = tabComponent.querySelectorAll(".tab")
    if (!tabs.length)
      return false

    // Check if form errors
    // If errors, open tab with error, display error badge on tab
    let focusTab = null
    let focusInput = null
    tabs.forEach(tab => {
      const tabName = tab.dataset.name
      const panel = this.element.querySelector(`.tab__panel[data-tab="${tabName}"]`)
      if (panel) {
        const inputError = panel.querySelector(".oq-form-group.is-invalid")
        if (inputError) {
          if (!focusTab) {
            focusTab = tabName
            focusInput = inputError
          }
          tab.classList.add("has-error")
        }
      }
    })
    // Open tab and scroll
    if (focusTab) {
      tabController.selectTabByName(focusTab)
      focusInput.scrollIntoView();
    }
  }
}

class Form {
  #form;
  #inputs = [];
  #conditionalDisplays = [];
  #submitters = [];
  #isValid;

  constructor(form) {
    this.#form = form;
    this.bindInputs();
    this.bindConditionalDisplays();
    this.validate();
  }

  bindInputs() {
    this.#inputs = [];
    this.#form.querySelectorAll("input,textarea,select").forEach(input => {
      this.#inputs.push(new Input(this, input));
    })
    this.#submitters = this.#form.querySelectorAll("[type='submit']");
  }

  bindConditionalDisplays() {
    this.#conditionalDisplays = [];
    this.#form.querySelectorAll("[data-display-if]").forEach(elem => {
      const condition = elem.dataset.displayIf.split(/:(.*)/s)
      const input_name = condition[0]
      const input_value = condition[1]
      const trigger_input = this.#inputs.find(i => i.input.getAttribute("name")?.endsWith(`[${input_name}]`));
      if (trigger_input) {
        trigger_input.input.addEventListener("change", e => {
          if (trigger_input.value === input_value) {
            elem.classList.remove("hidden");
          } else {
            elem.classList.add("hidden");
          }
        })
      }
    });
  }

  validate() {
    let isValid = true
    this.#inputs.forEach(input => {
      if (!input.isValid) {
        isValid = false;
      }
    })
    this.#isValid = isValid;
    if (this.#isValid)
      this.enableSubmitters();
    else
      this.disableSubmitters();
    return this.#isValid;
  }

  disableSubmitters() {
    this.#submitters.forEach((submitter) => {
      submitter.setAttribute("disabled", true);
    });
  }

  enableSubmitters() {
    this.#submitters.forEach(submitter => {
      submitter.removeAttribute("disabled");
    })
  }

  requestSubmit() {
    this.#form.requestSubmit();
  }
}

class Input {
  #form;
  #input;
  #initialValue;
  #value;

  #isRequired;
  isValid;

  constructor(form, input) {
    this.validate = this.validate.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.#form = form;
    this.#input = input;
    switch (input.tagName.toLowerCase()) {
      case "input":
      case "textarea":
        this.#input.addEventListener("input", this.onValueChange);
        break;
      case "select":
        this.#input.addEventListener("change", this.onValueChange);
        break;
    }
    this.#initialValue = this.#input.value;
    this.#value = this.#initialValue;
    this.#isRequired = this.#input.hasAttribute('required');
    this.isValid = this.validate();
  }

  validate() {
    if (this.#isRequired && !this.#value?.trim())
      return false;
    return true;
  }

  onValueChange(e) {
    this.#value = this.#input.value;
    let isValid = this.validate();
    if (this.isValid !== isValid) {
      this.isValid = isValid;
      this.#form.validate();
    }
  }

  hasChanged() {
    return this.#value !== this.#initialValue;
  }

  get input() {
    return this.#input;
  }

  get value() {
    return this.#value;
  }
}
