import {Controller} from "@hotwired/stimulus";
// import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["picture", "pictureIndex"];
  static values = {picturesLen: Number}

  #index = 0
  connect() {
    this.updateDisplay()
  }

  previous() {
    if (this.#index > 0) {
      this.#index -= 1
    } else {
      this.#index = this.picturesLenValue - 1
    }
    this.updateDisplay()
  }

  next() {
    
    if (this.#index < this.picturesLenValue - 1) {
      this.#index += 1
    } else {
      this.#index = 0
    }
    this.updateDisplay()
  }

  updateDisplay() {
    this.pictureTargets.forEach (p => {
      if (p.dataset.index == this.#index) {
        p.classList.remove("hidden")
      } else {
        p.classList.add("hidden")
      }
    })
    this.pictureIndexTarget.innerText = this.#index + 1
  }
}
