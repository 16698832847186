// Doc : https://www.stimulus-components.com/docs/stimulus-popover

import Popover from 'stimulus-popover';
import { useClickOutside } from 'stimulus-use'
export default class extends Popover {
  connect() {
    super.connect()
    useClickOutside(this)
  }

  clickOutside(event) {
    this.hide();
  }
}