import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggler", "panel"];
  static values = {name: String, scrollDownOnOpen: Boolean, initiallyOpen: Boolean};

  isOpen = false;

  initialize() {
    this.open = this.open.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  connect() {
    this.bindTogglers();
    if (this.initiallyOpenValue) {
      this.open();
    }
  }

  bindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-accordion="${this.nameValue}"]`).forEach(
      (elem) => {
        // const scrollTo = elem.dataset.modalScrollToId;
        elem.addEventListener(
          "click",
          this.toggle
        );
      }
    );
  }

  toggle(e) {
    if (e.target instanceof HTMLAnchorElement) {
      return;
    }
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    return this.isOpen ? this.close() : this.open();
  }

  open() {
    this.element.classList.add("open");
    this.panelTarget.classList.add("open");
    this.panelTarget.style.removeProperty("maxHeight");
    this.isOpen = true;

    if (this.scrollDownOnOpenValue) {
      setTimeout(() => {
        window.scrollTo({top: document.body.scrollHeight, behavior: "smooth"});
      }, 300);
    }
    setTimeout(() => {
      this.panelTarget.style.overflow = "visible"
    }, 300);
  }

  close() {
    this.panelTarget.style.removeProperty("overflow")
    this.element.classList.remove("open");
    this.panelTarget.classList.remove("open");
    this.panelTarget.style.maxHeight = null;
    this.isOpen = false;
  }
}
