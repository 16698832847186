import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Dropdown from "stimulus-dropdown";
import Notification from "stimulus-notification";

const application = Application.start()

application.register('dropdown', Dropdown)
application.register('notification', Notification)

const context = require.context(".", true, /\.js$/)
const contextComponents = require.context("../../../components/", true, /^.*(_controller|component)\.js$/)
const contextComponents2 = require.context("../../../components/admin", true, /^.*(_controller|component)\.js$/)

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents),
    definitionsFromContext(contextComponents2),
  )
)
