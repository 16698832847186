import {Controller} from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input";

export default class extends Controller {
  #errorMap = ["Numéro invalide", "Code pays invalide", "Trop court", "Trop long", "Numéro invalide", "Numéro invalide"];
  #iti;
  #input

  initialize() {
    this.#input = this.element
    this.onInput = this.onInput.bind(this)
    this.onChange = this.onChange.bind(this)
    this.loadScript();
  }
  async loadScript() {
    this.#iti = intlTelInput(this.#input, {
      initialCountry: "fr",
      hiddenInput: "phone",
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
    });
    this.element.addEventListener('input', this.onInput)
    this.element.addEventListener('change', this.onChange)
  };

  onInput() {
    this.reset();
    if (this.element.value.trim()) {
      if (this.#iti.isValidNumber()) {
      } else {
        const inputGroup = this.element.closest('.oq-input-group')
        if (inputGroup) {
          let errorNode = inputGroup.querySelector(".field-error")
          if (!errorNode) {
            errorNode = document.createElement('div')
            errorNode.classList.add('field-error')
          }
          var errorCode = this.#iti.getValidationError();
          errorNode.innerHTML = errorCode in this.#errorMap ? this.#errorMap[errorCode] : "Numéro invalide";
          inputGroup.appendChild(errorNode)
        }
      }
    }
  }

  onChange() {
    const hiddenInput = this.element.closest('.oq-input-group').querySelector('input[type="hidden"]')
    hiddenInput.value = this.#iti.getNumber()
  }
  reset() {
    this.element.classList.remove('is-danger')
    const errorNode = this.element.closest('.oq-input-group').querySelector(".field-error")
    if (errorNode)
      errorNode.remove()
  };

}
