import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['multiSelectInput', 'resultsList']
  static values = {
    searchApiUrl: String,
    dynamicParamName: String,
    dynamicParamElem: String,
  }

  _dynamicParamElem = null;

  connect() {
    if (this.dynamicParamElemValue !== 'undefined') {
      this._dynamicParamElem = document.querySelector(`#${this.dynamicParamElemValue}`);
    }
  }

  searchResults() {
    let span = document.createElement('span');
    span.appendChild(document.createTextNode('Recherche en cours...'));
    this.resultsListTarget.innerHTML = span.outerHTML;
    if (this._dynamicParamElem && this.dynamicParamNameValue) {
      if (!this._dynamicParamElem.value || this._dynamicParamElem.value === '') {
        let span = document.createElement('span');
        span.appendChild(document.createTextNode('Veuillez sélectionner une ville.'));
        span.classList.add('text-danger', 'font-bold');
        this.resultsListTarget.innerHTML = span.outerHTML;
        return;
      }
    }

    let apiURL = `${this.searchApiUrlValue}?${this.dynamicParamNameValue}=${this._dynamicParamElem.value}`;
    this.search(apiURL).then(
      ((results) => {
        this.displayResults(results);
      }).bind(this)
    );
  }

  search(apiURL) {
    return fetch(apiURL)
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          return data.results;
      });
  }

  displayResults(results) {
    this.resultsListTarget.innerHTML = '';
    results.forEach((result => {
      let result_elem = this.createResultElem(result);
    }).bind(this));
    let elem = document.createElement('div');
    elem.appendChild(document.createTextNode('Tout ajouter'));
    elem.classList.add('underline', 'cursor-pointer', 'basis-full')
    elem.setAttribute('data-action', 'click->city-managers-cities#addAllResults')
    this.resultsListTarget.appendChild(elem);
  }

  addResult(e) {
    this.createOptionForSelect(e.target.dataset.cityId, e.target.dataset.cityName);
  }

  addAllResults() {
    let allResults = this.resultsListTarget.querySelectorAll('.near-city-result');
    allResults.forEach((result => {
      this.createOptionForSelect(result.dataset.cityId, result.dataset.cityName);
    }).bind(this));
  }

  createResultElem(result) {
    let elem = document.createElement('div');
    let text = document.createTextNode(`+ ${result.name}`);
    elem.classList.add('near-city-result', 'cm-city-select-elem');
    elem.appendChild(text);
    elem.setAttribute('data-action', 'click->city-managers-cities#addResult');
    elem.setAttribute('data-city-id', result.id);
    elem.setAttribute('data-city-name', result.name);
    this.resultsListTarget.appendChild(elem);

    return elem;
  }

  createOptionForSelect(city_id, city_name) {
    let option = document.createElement('option');
    option.setAttribute("selected", "selected");
    option.setAttribute("value", city_id);
    let txt = document.createTextNode(city_name);
    option.appendChild(txt);
    if (!this.optionAlreadySelected(city_id))
      this.multiSelectInputTarget.appendChild(option);
  }

  optionAlreadySelected(city_id) {
    let selected_cities = [...this.multiSelectInputTarget.options].filter(option => option.selected).map(option => option.value);
    if (selected_cities.includes(city_id.toString()))
      return true;
    else
      return false;
  }
}
