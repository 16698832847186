import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String, linkTarget: String }

    connect() {
    }

    onClick(e) {
        if (!this.urlValue)
            return false;
        e.preventDefault()
        e.stopPropagation()
        if (this.linkTargetValue) {
            window.open(this.urlValue, this.linkTargetValue).focus();
        } else {
            window.location.href = this.urlValue;
        }
    }
}
