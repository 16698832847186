import {Controller} from "@hotwired/stimulus"
import {template} from "lodash";

export default class extends Controller {
  static targets = ["cityList", "cityBadgeTemplate", "addButton", "citiesInput"]

  #cityBadgeTemplate;
  #cityResults;

  connect() {
    this.#cityBadgeTemplate = template(this.cityBadgeTemplateTarget.innerHTML);
    this.addButtonTarget.style.display = 'none'
    this.#cityResults = []
  }

  async searchCities({params: {searchUrl}}) {
    this.#cityResults = []
    let zipcodeInput = this.element.querySelector("form input[name$='[postal_code]']")

    let zipcode = zipcodeInput.value

    let apiURL = `${searchUrl}?city_zipcode=${zipcode}`;
    let results = await fetch(apiURL)
      .then(response => response.json())
      .then(data => data.results)
    this.#cityResults = results
    this.displayResults()
  }

  displayResults() {
    this.cityListTarget.innerHTML = ""
    this.addButtonTarget.style.display = 'none'
    this.#cityResults.forEach((result) => {
      const itemData = {
        cityName: result.name,
      };
      const item = this.createElementFromHTML(this.#cityBadgeTemplate(itemData));
      this.cityListTarget.appendChild(item);
    })
    if (this.#cityResults.length > 0) {
      this.addButtonTarget.style.display = 'block'
    }
  }

  toggleOne() {

  }

  addAllCities() {
    const selectController = this.citiesInputTarget['form--collection-select--component']
    const options = this.#cityResults.map(result => ({
      text: result.name,
      value: result.id
    }))
    selectController.addOptions(options)
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }
}