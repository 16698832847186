import {Controller} from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["inputWrapper", "ibanInput"]

    _input = null

    connect() {
        this._input = this.inputWrapperTarget.getElementsByTagName('input')[0]
        this._input.style.display = 'none'
        this.updateInputStyle()
        if (this._input.value) {
            this.update_fields(this._input.value)
        }
        this.ibanInputTargets.forEach(((input) => {
            input.addEventListener('input', ((e) => this.on_input(e, input)).bind(this))
            input.addEventListener('keydown', ((e) => this.on_keydown(e, input)).bind(this))
            input.addEventListener('focus', ((e) => this.on_focus(e, input)).bind(this))
            input.addEventListener('paste', ((e) => this.on_paste(e, input)).bind(this))
        }).bind(this))
    }

    on_focus(e, input) {
        input.select()
    }

    on_input(e, input) {
        if (input.value.length >= parseInt(input.getAttribute('maxlength'))) {
            // Focus next input
            const nextInput = input.nextSibling
            if (nextInput)
                nextInput.focus()
        }
    }

    on_keydown(e, input) {
        if (e.key === "Backspace") {
            if (input.value.length == 0) {
                e.preventDefault()
                input.value = ""
                const prevInput = input.previousSibling
                if (prevInput)
                    prevInput.focus()
            }
        }
    }

    on_paste(e, input) {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        paste = paste.replace(/\s/g, '');
        this.update_fields(paste)
    }

    update_fields(value) {
        const fields_values = value.match(/.{1,4}/g);
        this.ibanInputTargets.forEach(((input, index) => {
            const val = fields_values[index] === undefined ? '' : fields_values[index]
            input.value = val
        }).bind(this))
    }

    update() {
        let iban = ""
        this.ibanInputTargets.forEach((input) => {
            iban += input.value
        })
        this._input.value = iban.toUpperCase()
    }

    updateInputStyle() {
        if (this._input.classList.contains("is-danger")) {
            this.ibanInputTargets.forEach((input) => {
                input.classList.add("is-danger")
            })
        }
    }
}
