import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["filterForm"]
  static values = {gridName: String}


  #customFilterForm = null

  initialize() {
    this.submitNewCustomFilterForm = this.submitNewCustomFilterForm.bind(this)
  }

  connect() {
    this.#customFilterForm = this.element.querySelector(`form[data-form='custom-form'][data-grid='${this.gridNameValue}']`)
    if (this.#customFilterForm) {
      this.#customFilterForm.addEventListener("submit", this.submitNewCustomFilterForm)
    }
  }

  submitNewCustomFilterForm(e) {
    e.preventDefault();
    const form = e.currentTarget
    const filtersInput = form.querySelector("input[name='filters']")
    const data = new FormData(this.filterFormTarget);
    let d = {};
    for (const pair of data.entries()) {
      let key = pair[0];
      const value = pair[1];
      const regex = /([A-Za-z_]+)\[([A-Za-z_]*)\](.*)/;
      key = key.replace(regex, "$2$3");
      if (key in ["scope", "search"])
        continue
      if (key.endsWith("[]")) {
        const keyclean = key.substring(0, key.length - 2);
        if (!d[keyclean])
          d[keyclean] = [];
        d[keyclean].push(value);
      } else {
        d[key] = value;
      }
    }
    const json = JSON.stringify(d);
    const hiddenInput = form.querySelector("input[name='filters']")
    hiddenInput.value = json
    form.submit();
  }
}
