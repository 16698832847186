import {Controller} from "@hotwired/stimulus";
import moment from "moment";
import {template} from "lodash";

export default class extends Controller {
  static targets = ["displayTitle", "slotsContainer", "weekDayTemplate", "monthDayTemplate", "eventTemplate"];
  static values = { eventsUrl: String, viewScale: String }

  #dayTemplate;
  #eventTemplate;
  #viewScale;
  #selected_date;
  #startOfMonth;
  #endOfMonth;
  #startOfWeek;
  #endOfWeek;
  #firstViewDay;
  #lastViewDay;
  #events;

  initialize() {
    moment.locale('fr')
  }

  connect() {
    this.#eventTemplate = template(this.eventTemplateTarget.innerHTML);

    this.#selected_date = moment(new Date());
    this.#viewScale =  this.viewScaleValue || 'month';

    switch (this.#viewScale) {
      case "week":
        this.#dayTemplate = template(this.weekDayTemplateTarget.innerHTML)
        break
      case "month":
        this.#dayTemplate = template(this.monthDayTemplateTarget.innerHTML)
        break
    }

    this.initializeValues();
  }

  initializeValues() {
    this.#startOfMonth = moment(this.#selected_date).startOf('month');
    this.#endOfMonth = moment(this.#selected_date).endOf('month');
    this.#startOfWeek = moment(this.#selected_date).startOf('week');
    this.#endOfWeek = moment(this.#selected_date).endOf('week');

    this.#firstViewDay = moment(this.#selected_date)
    this.#lastViewDay = moment(this.#selected_date)

    switch (this.#viewScale) {
      case "week":
        this.#firstViewDay = moment(this.#startOfWeek)
        this.#lastViewDay = moment(this.#endOfWeek)
        break
      case "month":
        this.#firstViewDay = moment(this.#startOfMonth).startOf('week');
        this.#lastViewDay = moment(this.#endOfMonth).endOf('week')
        break
      case "year":
        break
    }
    this.renderCalendar();
  }

  today() {
    this.#selected_date = moment(new Date());
    this.initializeValues();
    this.renderCalendar();
  }

  next() {
    this.#selected_date.add(1, this.#viewScale)
    this.initializeValues();
  }

  prev() {
    this.#selected_date.subtract(1, this.#viewScale)
    this.initializeValues();
  }

  fetchEvents() {
    if (!this.eventsUrlValue) {
      return false;
    }

    const queryParams = new URLSearchParams({
      from_date: this.#firstViewDay.format("Y-MM-DD"),
      to_date: this.#lastViewDay.format("Y-MM-DD"),
    });
    fetch(`${this.eventsUrlValue}?${queryParams}`)
      .then(response => response.json())
      .then((data) => {
        this.clearEvents()
        data.slots.sort(function (a, b) {
          return new Date(a.start_time) - new Date(b.start_time);
        }).forEach(event => {
          this.createEvent("slot", event.start_time, event.end_time, event.name, "success")
        })
        data.events.sort(function (a, b) {
          return new Date(a.start_time) - new Date(b.start_time);
        }).forEach(event => {
          this.createEvent("event", event.start_time, event.end_time, event.name, event.color)
        })
      })
  }

  clearEvents() {
    this.element.querySelectorAll('.event').forEach(e => e.remove());
  }

  createEvent(type, from, to, title, color = "gray") {
    const startTime = moment(from)
    const endTime = moment(to)
    const timeSlot = this.element.querySelector(`time[datetime='${startTime.format("Y-MM-DD")}']`)

    if (!timeSlot)
      return;

    let timeText = startTime.format("H[h]")
    if (startTime.minutes() > 0)
      timeText += `:${startTime.format("mm")}`
    timeText += "-"
    timeText += endTime.format("H[h]")
    if (endTime.minutes() > 0)
      timeText += `:${endTime.format("mm")}`
    const daySlot = timeSlot.closest('.day-slot')
    const parent = daySlot.querySelector('.events-container')
    if (type == "slot") {
      daySlot.classList.add("available")
      daySlot.querySelector('.free-slot').innerText = timeText
    } else {
      const eventNode = this.createElementFromHTML(this.#eventTemplate({
        eventTitle: title,
        eventTime: timeText,
        color: color,
      }));
      parent.appendChild(eventNode)
    }
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  }

  renderCalendar() {
    this.slotsContainerTarget.innerHTML = ""
    let displayTitle = ""
    switch (this.#viewScale) {
      case "week":
        displayTitle = this.#selected_date.format("MMMM Y [- Semaine] W")
        break
      case "month":
        displayTitle = this.#selected_date.format("MMMM Y")
        break
      case "year":
        displayTitle = this.#selected_date.format("Y")
        break
    }
    this.displayTitleTarget.innerText = displayTitle
    let day = moment(this.#firstViewDay)
    let today = moment().format('YMMD')
    while (day < this.#lastViewDay) {
      const isCurrentMonth = (day.month() == this.#selected_date.month())
      const isToday = (day.format("YMMD") == today)

      const dayNode = this.createElementFromHTML(this.#dayTemplate({
        day: day.format("D"),
        datetime: day.format("Y-MM-DD"),
      }));

      if (isToday)
        dayNode.classList.add("today")
      if (isCurrentMonth)
        dayNode.classList.add("current-month")

      this.slotsContainerTarget.appendChild(dayNode);
      day.add(1, 'day');
    }
    this.fetchEvents()
  }

}
