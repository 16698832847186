import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["input", "button"]
    static values = {filesList: String}

    filesList = null
    form = null

    files = []

    initialize() {
        this.filesList = document.querySelector(`#${this.filesListValue}`)
        this.onInputChange = this.onInputChange.bind(this)
        this.clearFileInputValue = this.clearFileInputValue.bind(this)
        this.openDialog = this.openDialog.bind(this)
        this.form = this.element.closest("form")
    }

    connect() {
        this.inputTarget.addEventListener("change", this.onInputChange)
        this.form.addEventListener("reset", this.clearFileInputValue)
        this.buttonTarget.addEventListener("click", this.openDialog)
    }

    disconnect() {
        this.inputTarget.removeEventListener("change", this.onInputChange)
        this.form.removeEventListener("reset", this.clearFileInputValue)
        this.buttonTarget.removeEventListener("click", this.openDialog)
    }

    openDialog(e) {
        e.preventDefault()
        e.stopPropagation()
        this.inputTarget.click()
    }

    clearFileInputValue() {
        this.inputTarget.value = null
        this.files = []
        this.updateFilePreview()
    }

    onInputChange(e) {
        e.stopPropagation()
        e.preventDefault()

        const fileList = this.inputTarget.files
        this.files = this.files.concat(Array.from(fileList))
        this.updateFileInputValue()
    }

    updateFileInputValue() {
        var b = new DataTransfer()
        for (var i = 0, len = this.files.length; i < len; i++) b.items.add(this.files[i])
        this.inputTarget.files = b.files
        const fileList = this.inputTarget.files
        this.updateFilePreview()
    }

    updateFilePreview() {
        this.filesList.innerHTML = ""

        this.files.forEach((file) => {
            let preview = document.createElement("div")
            preview.classList.add("file-preview")
            preview.innerHTML = file.name

            let deleteBtn = document.createElement("div")
            deleteBtn.classList.add("delete-btn")
            deleteBtn.innerHTML = "<svg class='w-4 h-4' xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\">\n" +
                "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M6 18L18 6M6 6l12 12\" />\n" +
                "</svg>"
            deleteBtn.addEventListener("click", ((e) => {
                this.deleteFile(file)
            }).bind(this))
            preview.append(deleteBtn)
            this.filesList.append(preview)
        })
    }

    deleteFile(file) {
        let index = this.files.indexOf(file);
        if (index !== -1) {
            this.files.splice(index, 1);
        }
        this.updateFileInputValue()
    }
}
