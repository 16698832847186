import Lightbox from 'stimulus-lightbox'
import lightGallery from 'lightgallery'
import lgRotate from 'lightgallery/plugins/rotate'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'

export default class extends Lightbox {
  static values = {container: String, closable: Boolean, open: Boolean}

  connect() {
    this.lightGallery = lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
      container: this.containerValue,
      closable: this.closableValue,
      plugins: [lgZoom, lgRotate, lgFullscreen]
    })
    if (this.openValue)
      this.lightGallery.openGallery()
  }
}
