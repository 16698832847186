import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "overlay", "contentFrame"];
  static values = { name: String };

  initialize() {
    this.close = this.close.bind(this);
    this.onTurboLoad = this.onTurboLoad.bind(this);
  }

  connect() {
    document.addEventListener("turbo:frame-render", (e) => this.onTurboLoad(e));

    if (this.element.classList.contains("open")) {
      // Prevent body scroll
      const body = document.querySelector("body");
      body.style.overflow = "hidden";
    }
  }

  disconnect() {
    document.removeEventListener("turbo:frame-render", this.onTurboLoad);
  }

  close() {
    this.element.classList.remove("open");

    // Restore body scroll
    const body = document.querySelector("body");
    body.style.overflow = "auto";

    // Add class .close for 0.6s for SCSS animation
    this.element.classList.add("close");
    setTimeout(() => {
      this.element.classList.remove("close");
      // Reset turbo-frame
      const frame = this.element.querySelector("turbo-frame#trboFrameContent");
      frame.innerHTML = "";
      frame.src = "";
      this.loaderTarget.classList.remove("hidden");
    }, 600);
  }

  onTurboLoad() {
    this.loaderTarget.classList.add("hidden");
  }
}
