import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["map"];

  static values = { lat: Number, lng: Number, mapboxToken: String, static: Boolean, staticImageUrl: String };

  mapboxgl = null;
  loaded = false;
  staticMarker = null;

  connect() {
    if (this.staticValue) {
      this.element.addEventListener("click", this.initDynamicMap.bind(this));
      window.addEventListener("load", this.initStaticMap.bind(this));
    } else {
      this.initDynamicMap();
    }
  }

  initStaticMap() {
    if (!this.loaded && this.hasMapTarget) {
      const height = this.mapTarget.offsetHeight;
      const width = this.mapTarget.offsetWidth;
      const image_url =
        this.staticImageUrlValue ||
        `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${this.lngValue},${this.latValue},12/${width}x${height}?access_token=${this.mapboxTokenValue}`;
      this.mapTarget.style.backgroundImage = `url("${image_url}")`;
      this.mapTarget.style.backgroundPosition = "center";
      this.mapTarget.style.backgroundSize = "cover";
      this.mapTarget.style.backgroundRepeat = "no-repeat";

      if (!this.staticMarker) {
        this.staticMarker = this.createMarker();
        this.mapTarget.append(this.staticMarker);
        window.addEventListener("resize", this.initStaticMap.bind(this));
      }
      const left = width / 2 - 24;
      const top = height / 2 - 24;
      this.staticMarker.style = `position: absolute; left: ${left}px; top: ${top}px`;
    }
  }

  initDynamicMap() {
    if (!this.loaded && this.hasMapTarget) {
      if (!this.mapboxgl) {
        import("!mapbox-gl").then(
          ((mapboxgl) => {
            this.mapboxgl = mapboxgl.default;
            this.loadDynamicMap();
          }).bind(this)
        );
      } else {
        this.loadDynamicMap();
      }
    }
  }

  loadDynamicMap() {
    this.loaded = true;
    this.staticMarker.remove();
    window.removeEventListener("resize", this.initStaticMap.bind(this));
    this.mapboxgl.accessToken = this.mapboxTokenValue;
    this._map = new this.mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.lngValue, this.latValue],
      zoom: 12,
    })
      .addControl(new this.mapboxgl.FullscreenControl())
      .addControl(new this.mapboxgl.NavigationControl());

    this._map.scrollZoom.disable();
    this._map.on(
      "load",
      (() => {
        this.resizeMap();
        const mapMarker = this.createMarker();
        this._marker = new this.mapboxgl.Marker({
          element: mapMarker,
        })
          .setLngLat([this.lngValue, this.latValue])
          .addTo(this._map);
        // this.updateMapCoordinates([this.lngValue, this.latValue])
      }).bind(this)
    );
  }

  createMarker() {
    let marker = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    marker.setAttribute("width", "34");
    marker.setAttribute("height", "51");
    marker.setAttribute("viewBox", "0 0 34 51");

    marker.innerHTML =
      '\
      <ellipse opacity="0.15" cx="16.9996" cy="41.783" rx="15.7857" ry="9.21687" fill="url(#radial-blue-purple) blue"/>\
      <ellipse opacity="0.43" cx="16.9996" cy="41.7831" rx="7.28571" ry="4.30121" fill="url(#radial-blue-purple) blue"/>\
      <path d="M34 17.2048C34 24.6601 23.5333 36.3049 19.0255 40.9744C17.9085 42.1315 16.0915 42.1315 14.9745 40.9744C10.4667 36.3049 0 24.6601 0 17.2048C0 12.6418 1.79107 8.26571 4.97919 5.03918C8.1673 1.81265 12.4913 0 17 0C21.5087 0 25.8327 1.81265 29.0208 5.03918C32.2089 8.26571 34 12.6418 34 17.2048Z" fill="url(#radial-blue-purple) blue"/>\
    ';
    return marker;
  }

  updateMapCoordinates(coordinates) {
    this._map.jumpTo({
      center: coordinates,
      zoom: 12,
    });
    this._marker.setLngLat(coordinates);
    this._marker.getElement().style.visibility = "visible";
  }

  resizeMap() {
    this.mapTarget.style.width = "100%";
    this._map.resize();
  }
}
