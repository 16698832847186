import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autocompleteInput", "autocompleteList"];

  static values = { apiUrl: String };

  navKeys = ["Enter", "ArrowUp", "ArrowDown"];
  currentActiveElement = -1;
  _dropdownController = null;
  selectionList = [];

  initialize() {
    this.element[this.identifier] = this;
    this.onInputEvent = this.onInputEvent.bind(this);
    this.onKeydownEvent = this.onKeydownEvent.bind(this);
    this.onClickEvent = this.onClickEvent.bind(this);
  }

  connect() {
    this.autocompleteInputTarget.addEventListener("input", this.onInputEvent);
    this.autocompleteInputTarget.addEventListener("keydown", this.onKeydownEvent);
    this.autocompleteInputTarget.addEventListener("click", this.onClickEvent);
  }

  disconnect() {
    this.autocompleteInputTarget.removeEventListener("input", this.onInputEvent);
    this.autocompleteInputTarget.removeEventListener("keydown", this.onKeydownEvent);
    this.autocompleteInputTarget.removeEventListener("click", this.onClickEvent);
  }

  fillAutocompleteList(results = []) {
    this.currentActiveElement = -1;
    this.autocompleteListTarget.innerHTML = "";
    results.forEach((result) => {
      let item = document.createElement("li");
      let textElem = document.createElement("span");
      textElem.innerText = result.name;

      // On désactive les propriétaires moraux si un propriétaire physique est sélectionné
      if (this.selectionList.map((e) => e.type).includes("Landlord") && result.type === "MoralLandlord") item.classList.add("autocompleteList__item--disabled");

      if (this.selectionList.map((e) => e.name).includes(result.name)) item.classList.add("autocompleteList__item--disabled-selected");
      item.appendChild(textElem);
      item.tabIndex = 1;
      item.classList.add("autocompleteList__item");
      item.dataset.action = "click->dropdown--component#hide";
      item.dataset.landlord_type = result.type;
      item.addEventListener(
        "click",
        ((e) => {
          this.selectResult(result);
        }).bind(this)
      );
      this.autocompleteListTarget.append(item);
    });
    if (!results.length) {
      let element = document.createElement("span");
      element.classList.add("tempMsg");
      element.innerText = "Aucun résultat trouvé";
      this.autocompleteListTarget.append(element);
    }
  }

  async search(query) {
    let element = document.createElement("span");
    element.classList.add("tempMsg");
    element.innerText = "Recherche en cours...";
    !this.autocompleteListTarget.children.length && this.autocompleteListTarget.append(element);

    const apiURL = `${this.apiUrlValue}&search=${query}`;
    try {
      const response = await fetch(apiURL);
      const { results } = await response.json();

      return results;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  selectResult(result) {
    this.selectionList.push(result);
    this.dispatchCustomEvent();
  }

  dispatchCustomEvent() {
    const newSelection = new CustomEvent("selection", {
      detail: {
        resultList: this.selectionList,
      },
    });
    this.element.dispatchEvent(newSelection);
  }

  deleteResult(itemToRemove) {
    const index = this.selectionList.indexOf(itemToRemove);
    if (index > -1) {
      this.selectionList.splice(index, 1);
    }
    this.dispatchCustomEvent();
  }

  getDropdownController() {
    return (
      this._dropdownController || (this._dropdownController = this.element.querySelector("[data-controller='dropdown--component']")["dropdown--component"])
    );
  }

  onClickEvent(e) {
    e.stopPropagation();
    e.preventDefault();
    this.autocompleteInputTarget.dispatchEvent(new Event("input"));
  }

  onInputEvent(e) {
    let target = e.currentTarget;
    if (!this.element.classList.contains("autocomplete--disabled")) this.getDropdownController().toggle();
    this.search(target.value).then(
      ((results) => {
        this.fillAutocompleteList(results);
      }).bind(this)
    );
  }

  onKeydownEvent(e) {
    if (!this.navKeys.includes(e.key)) return true;
    e.preventDefault();
    this.handleKeyboardNav(e);
  }

  // Navigation au clavier dans le dropdown
  handleKeyboardNav(event = {}) {
    const key = event.key;

    let resultsList = this.autocompleteListTarget.children;
    this.autocompleteListTarget.querySelectorAll("li.active").forEach((element) => {
      element.classList.remove("active");
    });

    if (key === "ArrowDown") {
      let currentActiveElementIsNotLastItem = this.currentActiveElement < resultsList.length - 1;
      if (currentActiveElementIsNotLastItem) {
        this.currentActiveElement++;
      }
    } else if (key === "ArrowUp") {
      let currentActiveElementIsNotFirstItem = this.currentActiveElement > 0;
      if (currentActiveElementIsNotFirstItem) {
        this.currentActiveElement--;
      }
    }

    let currentListItem = null;
    if (this.currentActiveElement >= 0) {
      currentListItem = resultsList[this.currentActiveElement];
      if (currentListItem.tagName === "SPAN") return;
      currentListItem.classList.add("active");
    }

    if (key === "Enter" && currentListItem) {
      currentListItem.click();
      this.currentActiveElement = -1;
    }
  }
}
