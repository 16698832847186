import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["elementRow", "elementBloc", "searchForm", "searchInput"]

  initialize() {
    this.search = this.search.bind(this)
  }

  connect() {
    // Initialize search form
    this.searchFormTarget.addEventListener("submit", this.search)
    this.searchInputTarget.addEventListener("change", this.search)
  }

  disconnect() {
    this.searchFormTarget.removeEventListener("submit", this.search)
    this.searchInputTarget.removeEventListener("change", this.search)
  }

  search(e) {
    e.preventDefault()
    const searchValue = this.searchInputTarget.value?.trim()

    if (!searchValue) {
      for (var i = 0; i < this.elementRowTargets.length; i++) {
        const row = this.elementRowTargets[i]
        row.classList.remove("hidden")
      }
      for (i = 0; i < this.elementBlocTargets.length; i++) {
        const bloc = this.elementBlocTargets[i]
        bloc.classList.remove("hidden")
      }
      return false
    }

    const expr = new RegExp(`${searchValue}`, "i")

    for (var i = 0; i < this.elementRowTargets.length; i++) {
      const row = this.elementRowTargets[i]
      if (expr.test(row.textContent)) {
        //match has been made
        row.classList.remove("hidden")
      } else {
        row.classList.add("hidden")
      }
    }

    for (i = 0; i < this.elementBlocTargets.length; i++) {
      const bloc = this.elementBlocTargets[i]

      const rows = bloc.querySelectorAll(".elementRow:not(.hidden)")
      if (rows.length) {
        bloc.classList.remove("hidden")
      } else {
        bloc.classList.add("hidden")
      }
    }
    return false
  }
}