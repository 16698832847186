import {Controller} from "@hotwired/stimulus"
import {template} from "lodash";

export default class extends Controller {
  static targets = ["inputTemplate", "inputList"]

  static values = {initialValue: String}

  #value = [];
  #inputTemplate;

  connect() {
    this.#inputTemplate = template(this.inputTemplateTarget.innerHTML);
    this.initializeValue();
  }

  initializeValue() {
    if (!this.initialValueValue || this.initialValueValue == "")
      return;
    const initialValue = JSON.parse(this.initialValueValue);
    initialValue.forEach(v => {
      this.#value.push(v);
      const btn = this.element.querySelector(`[data-form--collection-check-buttons--component-value-param="${v}"]`)
      if (btn) {
        btn.classList.add("btn--appearance-solid");
        btn.classList.remove("btn--appearance-outline");
      }
    })
    this.updateInputs();
  }

  toggleSelect(e) {
    const btn = e.currentTarget;
    const value = e.params.value.toString();
    btn.classList.toggle("btn--appearance-outline")
    btn.classList.toggle("btn--appearance-solid")
    if (this.#value.includes(value)) {
      const index = this.#value.indexOf(value);
      if (index > -1)
        this.#value.splice(index, 1);
    } else {
      this.#value.push(value)
    }
    this.updateInputs();
  }

  updateInputs() {
    this.inputListTarget.innerHTML = "";
    this.#value.forEach(v => {
      const input = this.createElementFromHTML(this.#inputTemplate({
        value: v,
      }));
      this.inputListTarget.appendChild(input);
    })
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }
}