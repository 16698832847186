import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  initialize() {
    this.element[this.identifier] = this;
  }
  connect() {
    this.initDisplay();
    this.dispatch("connected", { bubbles: true });
  }
  initDisplay() {
    let activeTab = this.tabTargets.find(t => t.classList.contains("active"));
    if (!activeTab)
      activeTab = this.tabTargets[0];
    this.displayTab(activeTab);
  }
  selectTab(e) {
    const tab = e.currentTarget;
    if (tab.classList.contains("disabled"))
      return false;
    this.displayTab(tab);
  }

  selectTabByName(tabName) {
    const tab = this.element.querySelector(`.tab[data-name="${tabName}"]`);
    if (!tab || tab.classList.contains("disabled"))
      return false;
    this.displayTab(tab);
  }

  displayTab(activeTab) {
    const tab = activeTab.dataset.name;
    this.tabTargets.filter(t => t.classList.contains("active")).forEach(t => t.classList.remove("active"));
    activeTab.classList.add("active");
    const activePanel = this.panelTargets.find(p => p.dataset.tab == tab);
    this.panelTargets.forEach(p => p.classList.add("hidden"));
    if (activePanel)
      activePanel.classList.remove("hidden");
  }
}
