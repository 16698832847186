import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "incrementBtn", "decrementBtn"];

  static values = {min: Number, max: Number}

  connect() {
    this.inputTarget.value ||= 0;
    this.update()
  }

  increment() {
    const val = parseInt(this.inputTarget.value) || 0
    if (!this.hasMaxValue || (val < this.maxValue)) {
      this.inputTarget.value = val + 1;
    }
    this.update();
  }

  decrement() {
    const val = parseInt(this.inputTarget.value) || 0
    if (!this.hasMinValue || (val > this.minValue)) {
      this.inputTarget.value = val - 1;
    }
    this.update();
  }

  update() {
    if (this.hasMinValue && this.inputTarget.value <= this.minValue) {
      this.inputTarget.value = this.minValue;
      this.decrementBtnTarget.setAttribute("disabled", true);
    } else {
      this.decrementBtnTarget.removeAttribute("disabled");
    }
    if (this.hasMaxValue && this.inputTarget.value >= this.maxValue) {
      this.inputTarget.value = this.maxValue;
      this.incrementBtnTarget.setAttribute("disabled", true);
    } else {
      this.incrementBtnTarget.removeAttribute("disabled");
    }
  }
}
