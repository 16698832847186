import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay"]
  static values = {name: String};

  _togglers = []

  initialize() {
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  connect() {
    this.bindTogglers();
  }

  disconnect() {
    this.unbindTogglers()
  }


  bindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-drawer="${this.nameValue}"]`).forEach(
      ((elem) => {
        elem.addEventListener("click", this.open)
        this._togglers.push(elem)
      }).bind(this)
    );
  }

  unbindTogglers() {
    this._togglers.forEach(
      ((elem) => {
        elem.removeEventListener("click", this.open)
      }).bind(this)
    );
    this._togglers = []
  }

  open(e) {
    if (e) e.preventDefault();
    this.element.classList.add("open");
  }

  close(e) {
    if (e) e.preventDefault();
    this.element.classList.remove("open");
  }
}
