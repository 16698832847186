import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['container', 'prevArrow', 'nextArrow']

    _elementBorderRight = 0
    _elementBorderLeft = 0
    _prevChild = null
    _nextChild = null

    initialize() {
        this.update = this.update.bind(this)
    }

    connect() {
        // Disable if only one child

        if (this.containerTarget.children.length <= 1) {
            this.prevArrowTarget.classList.add('disabled')
            this.nextArrowTarget.classList.add('disabled')
            return false
        }

        document.addEventListener("turbo:load", this.update);
        window.addEventListener('load', this.update);
        window.addEventListener('resize', this.update);
        this.containerTarget.addEventListener('scroll', this.update);
    }

    disconnect() {
        document.removeEventListener("turbo:load", this.update);
        window.removeEventListener('load', this.update);
        window.removeEventListener('resize', this.update);
        this.containerTarget.removeEventListener('scroll', this.update);
    }

    update() {
        // Calculate container limits
        this._elementBorderRight = this.containerTarget.clientWidth + this.containerTarget.scrollLeft
        this._elementBorderLeft = this.containerTarget.scrollLeft

        // Check if content is overflowing
        this._isOverflowing = this.containerTarget.clientWidth < this.containerTarget.scrollWidth
            || this.containerTarget.clientHeight < this.containerTarget.scrollHeight;

        // Check partially visible elems
        let children = this.containerTarget.children
        this._nextChild = null
        for (let i = 0; i < children.length; i++) {
            let child = children[i]
            let posEndX = child.offsetLeft + child.offsetWidth
            if (posEndX > this._elementBorderRight) {
                this._nextChild = child
                break
            }
        }
        this._prevChild = null
        for (let i = children.length - 1; i >= 0; i--) {
            let child = children[i]
            let posStartX = child.offsetLeft
            if (posStartX < this._elementBorderLeft) {
                this._prevChild = child
                break
            }
        }

        // Display/Hide navigation arrows
        if (this._nextChild) {
            this.nextArrowTarget.classList.remove('disabled')
        } else {
            this.nextArrowTarget.classList.add('disabled')
        }

        if (this._prevChild) {
            this.prevArrowTarget.classList.remove('disabled')
        } else {
            this.prevArrowTarget.classList.add('disabled')
        }

        if (!(this._prevChild || this._nextChild)) {
            this.element.classList.add("no-scroll")
        } else {
            this.element.classList.remove("no-scroll")
        }
    }

    scrollNext() {
        if (!this._nextChild)
            return false
        let scrollLeft = this._nextChild.offsetLeft - 96
        this.containerTarget.scrollTo(scrollLeft, 0)
        this.update()
    }

    scrollPrev() {
        if (!this._prevChild)
            return false
        let scrollLeft = this._prevChild.offsetLeft + this._prevChild.offsetWidth - this.containerTarget.clientWidth + 96
        this.containerTarget.scrollTo(scrollLeft, 0)
        this.update()
    }

}
